export enum ToastType {
  Success = "success",
  Error = "error",
}

export interface ISolarGikToastProps {
  open: boolean;
  onClose: () => void;
  title: string;
  message: string;
  type?: ToastType;
  autoHideDuration?: number | null;
  buttonText?: string;
}
