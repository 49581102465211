import React, { FC } from "react";
import { useSelector } from "react-redux";
import {
  DateTimePicker,
  DateTimePickerSlotProps,
  LocalizationProvider,
  multiSectionDigitalClockSectionClasses,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { RootState } from "../../features/app/Store";
import { sgDayjsLocale } from "../../features/app/DayjsUtils";

interface SgDateTimePickerBaseProps {
  utcValue: Date | null;
  setUtcValue: (value: Date | null) => void;
  label: string;
  className?: string;
  error?: boolean;
  slotProps?: DateTimePickerSlotProps<Dayjs, true>;
  disableFuture?: boolean;
  disablePast?: boolean;
}

const SgDateTimePickerBase: FC<SgDateTimePickerBaseProps> = (props) => {
  const siteTimezone = useSelector((state: RootState) => state.site.ianaTimeZoneName);
  const handleChange = (value: Dayjs | null) => {
    props.setUtcValue(value?.toDate() ?? null);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={sgDayjsLocale} localeText={{ fieldMonthPlaceholder: (params) => params.format }}>
      <DateTimePicker
        ampm={false}
        className={props.className}
        closeOnSelect={true}
        label={props.label}
        defaultValue={dayjs(props.utcValue)}
        value={dayjs(props.utcValue)}
        timezone={siteTimezone}
        onChange={handleChange}
        disableFuture={props.disableFuture ?? true}
        disablePast={props.disablePast ?? false}
        timeSteps={{ minutes: 1 }}
        slotProps={{
          ...props.slotProps,
          layout: {
            sx: {
              [`.${multiSectionDigitalClockSectionClasses.root}:after`]: {
                display: "none",
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default SgDateTimePickerBase;
