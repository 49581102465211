import { FC, ReactNode } from "react";

import Header from "./headerNav/Header";
import SiteNavigation from "./headerNav/SiteNavigation";
import classes from "./Layout.module.css";
interface LayoutProps {
  children: ReactNode;
  layoutStyle?: React.CSSProperties;
}
const Layout: FC<LayoutProps> = ({ children, layoutStyle }) => {
  return (
    <>
      <Header />
      <SiteNavigation />
      <div className={classes.children} style={layoutStyle}>
        {children}
      </div>
    </>
  );
};
export default Layout;
