import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { fetchTags } from "../../features/app/store/TagsChartStore";
import { failSafeInit, repetitivePolling } from "../AsyncUtils";
import APP_CONFIG from "../../features/app/configuration/AppConfig";
import { IFetchTagsParams } from "../../features/data_point/charts/ChartModel";
import { IHistoryTagsParams } from "../../features/data_point/models/TagChartModel";
import { AppDispatch } from "../../features/app/Store";

interface IFetchTagHistoryParams {
  paramsForGraphDisplayRequest: IHistoryTagsParams;
  key: string;
  isRepetitiveRequest: boolean;
}

const useFetchHistoryTags = (params: IFetchTagHistoryParams) => {
  const dispatch = useDispatch<AppDispatch>();

  const memoizedParams = useMemo(
    () => params.paramsForGraphDisplayRequest,
    [JSON.stringify(params.paramsForGraphDisplayRequest)]
  );
  const fetchTagsApi = async (signal: AbortSignal) => {
    if (signal.aborted) {
      return;
    }
    const request: IFetchTagsParams = {
      interval: memoizedParams.samplingIntervalInSeconds,
      paramsForGraphDisplayRequest: memoizedParams,
      key: params.key,
    };
    dispatch(fetchTags(request));
  };

  useEffect(() => {
    if (memoizedParams.tags.length === 0) {
      return;
    }

    const controller = new AbortController();

    if (params.isRepetitiveRequest) {
      repetitivePolling(
        "fetchTagsApi",
        fetchTagsApi,
        APP_CONFIG.milliSecBetweenGetHistory,
        APP_CONFIG.millisecondsBetweenChartHistoryFailedRequest,
        controller.signal
      );
    } else {
      failSafeInit(
        "fetchTagsApi",
        fetchTagsApi,
        APP_CONFIG.millisecondsBetweenChartHistoryFailedRequest,
        controller.signal
      );
    }
    return () => controller.abort();
  }, [memoizedParams]);
};

export default useFetchHistoryTags;
