import {
  MultisiteTableIcon,
  SubscribersIcon,
  TrendsIcon,
} from "../../../SolarGikLib/icons/common/navigation/NavIcons";
import { MultiSiteNavConfig } from "../../navigation/NavigationDrawer/NavigationDrawerModels";
import { UserAccessType } from "../../user/Models";

export const NavItemsConfig: MultiSiteNavConfig[] = [
  {
    pageName: "Multisite",
    pagePath: "/MultisiteTable",
    icon: MultisiteTableIcon,
    visiblityFilter: ({ userAccessType }) => userAccessType >= UserAccessType.ViewDashboard,
  },
  {
    pageName: "Trends",
    pagePath: "/Trends",
    icon: TrendsIcon,
    visiblityFilter: ({ userAccessType }) => userAccessType >= UserAccessType.Viewer,
  },
  {
    pageName: "Subscribers",
    pagePath: "/FaultSubscribers",
    icon: SubscribersIcon,
    visiblityFilter: ({ userAccessType }) => userAccessType >= UserAccessType.Admin,
  },
];
