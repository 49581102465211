import { FC, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SHA256, enc as Encoding } from "crypto-js";
import classes from "./CardSiteStyledLineChartWrapper.module.css";
import { getChartType } from "./Utils";
import Card from "../../SolarGikLib/cards/Card";
import SolarGikStyledChart from "../../SolarGikLib/SolarGikStyledChart";
import APP_CONFIG from "../app/configuration/AppConfig";
import { intervalStringToSeconds } from "../app/Formating";
import { RootState } from "../app/Store";
import { IChartTagConfig } from "../data_point/charts/ChartModel";
import { createLines } from "../data_point/charts/ChartTagsUtils";
import ExportHistoryTagsToCsvFile from "../data_point/ExportHistoryTagsToCsvFile";
import {
  IChartProps,
  IChartSingleLineConfig,
  IHistoryTagsParams,
} from "../data_point/models/TagChartModel";
import { IExtendedLineChartInfo } from "../data_point/models/TagsModels";
import { invalidTagMetadata } from "../app/TagsMetadataUtils";
import ChartCardSkeleton from "./ChartCardSkeleton";
import useFetchHistoryTags from "../../common/Hooks/useFetchHistoryTags";

const initLinesConfig: IChartSingleLineConfig[] = [];

const hash = (input: string) => SHA256(input).toString(Encoding.Hex);

const CardSiteStyledLineChartWrapper: FC<IChartTagConfig> = ({
  siteId,
  title,
  tagInfo,
  samplingInterval,
  timeRange,
  chartHeight,
  isRepetitiveRequest,
  isExportable,
  overrideShowLegend,
  useTagMetadataForMinMax = true,
  showChartTitle: isShowChartTitle = false,
}) => {
  const tagsIds = tagInfo.map((tag) => tag.id).join(",");
  const key = `${title}_${hash(siteId + tagsIds)}`;

  const multiSitesMetadata = useSelector((state: RootState) => state.multiSitesMetadata.sites);
  const tagsMetadata = multiSitesMetadata[siteId].tags;
  const [linesConfig, setLinesConfig] = useState(initLinesConfig);
  const [isDataExists, setIsDataExists] = useState(false);

  const tagsIdArrFunc = () =>
    tagInfo.map((tag) => {
      return tag.id;
    });
  const [tagsIdArr, setTagsIdArr] = useState<string[]>(tagsIdArrFunc());
  const newTagsArr = tagsIdArrFunc();
  if (tagsIdArr.length != newTagsArr.length || tagsIdArr.some((v, i) => v !== newTagsArr[i])) {
    setTagsIdArr(newTagsArr);
  }

  const chartData = useSelector((state: RootState) => {
    const siteData = state.multiSitesChartsData[siteId];
    return siteData ? siteData[key] : null;
  });

  const [tagWithGraphInfo, setTagWithGraphInfo] = useState<IExtendedLineChartInfo[]>([]);
  const [rangeTimeInZoneTimeState, setRangeTimeInZoneTimeState] = useState(timeRange);
  if (
    rangeTimeInZoneTimeState.start !== timeRange.start ||
    rangeTimeInZoneTimeState.end !== timeRange.end
  ) {
    setRangeTimeInZoneTimeState(timeRange);
  }

  useEffect(() => {
    if (!tagsMetadata || tagInfo.length == 0) {
      return;
    } else {
      const updatedTagWithGraphInfo = tagInfo.map((tag) => {
        const metadata = multiSitesMetadata[siteId]?.tags[tag.id] ?? invalidTagMetadata;
        return {
          id: tag.id,
          isCombinedYAxis: tag.isCombinedYAxis,
          displayName: tag.displayName ?? metadata?.displayName ?? "",
          min: useTagMetadataForMinMax ? metadata.low : undefined,
          max: useTagMetadataForMinMax ? metadata.high : undefined,
          customLineModifier: tag.customLineModifier,
        };
      });

      setTagWithGraphInfo(updatedTagWithGraphInfo);
    }
  }, [tagsMetadata, chartData]);
  const intervalInSeconds = intervalStringToSeconds(samplingInterval);

  const paramsForGraphDisplayRequest: IHistoryTagsParams = {
    siteId: siteId,
    tags: tagsIdArr,
    range: {
      start: timeRange.start,
      end: timeRange.end,
    },
    samplingIntervalInSeconds: intervalInSeconds,
  };

  const paramsForExport: IHistoryTagsParams = {
    siteId: siteId,
    tags: tagsIdArr,
    range: {
      start: timeRange.start,
      end: timeRange.end,
    },
    samplingIntervalInSeconds:
      tagsIdArr.length > 0 && tagsIdArr[0].includes("CurrentElevation")
        ? APP_CONFIG.trackersExportIntervalInSeconds
        : APP_CONFIG.defaultExportIntervalInSeconds,
  };

  const chartType = getChartType(rangeTimeInZoneTimeState.start, rangeTimeInZoneTimeState.end);
  const chartConfig: IChartProps = {
    title: title,
    timeRange: timeRange,
    samplingIntervalInSeconds: intervalInSeconds,
    chartHeight: chartHeight,
    tagInfo: tagInfo,
    overrideShowLegend,
    chartType: chartType,
    showChartTitle: isShowChartTitle,
  };

  useEffect(() => {
    if (!chartData || tagWithGraphInfo.length === 0) {
      return;
    }
    if (Object.keys(chartData.tagToValues).length !== tagWithGraphInfo.length) {
      return;
    }
    const currentLineConfig: IChartSingleLineConfig[] = createLines(
      chartData.tagToValues,
      tagWithGraphInfo
    );

    setLinesConfig(currentLineConfig);
    setIsDataExists(true);
  }, [chartData, tagWithGraphInfo]);

  useFetchHistoryTags({
    paramsForGraphDisplayRequest,
    key,
    isRepetitiveRequest,
  });

  if (tagInfo.length == 0) {
    // Site doesn't have the relevant data for this card, card with no content is shown.
    return (
      <Card
        title={title}
        contentStyle={{
          marginBottom: "0px",
        }}
      >
        <div className={classes.chart}>
          <SolarGikStyledChart chartConfig={chartConfig} linesConfig={linesConfig} />
        </div>
      </Card>
    );
  }

  return (
    <Card
      actions={
        isExportable && (
          <span className={classes["chart-header"]}>
            <ExportHistoryTagsToCsvFile params={paramsForExport} chartTitle={chartConfig.title} />
          </span>
        )
      }
      title={title}
      contentStyle={{
        marginBottom: "0px",
      }}
    >
      <div className={classes.chart}>
        <ChartCardSkeleton isDataExist={isDataExists}>
          <SolarGikStyledChart chartConfig={chartConfig} linesConfig={linesConfig} />
        </ChartCardSkeleton>
      </div>
    </Card>
  );
};
export default CardSiteStyledLineChartWrapper;
