import { FC } from "react";
import { useSelector } from "react-redux";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import SolarGikButton from "../../../SolarGikLib/Button";
import SgBackdrop from "../../../SolarGikLib/backdrop/SgBackdrop";
import { selectSiteDateTimeFormatter } from "../../sites/SiteStore";
import classes from "./MuteAlertToast.module.css";
import { muteAlertToastButtonStyle, muteAlertToastStyle } from "./MuteAlertStyle";

export enum MuteAlertToastActionType {
  Mute = "mute",
  Unmute = "unmute",
  Error = "error",
}

interface IMuteAlertToastAction {
  type: MuteAlertToastActionType.Mute;
  expirationDate: number;
}

interface IUnmuteAlertToastAction {
  type: MuteAlertToastActionType.Unmute;
}

interface IErrorAlertMuteToastAction {
  type: MuteAlertToastActionType.Error;
  errorMessage?: string;
}

export type ToastAction = IMuteAlertToastAction | IUnmuteAlertToastAction | IErrorAlertMuteToastAction;

interface IMuteAlertToastProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  action?: ToastAction;
}

const ToastTitles = {
  [MuteAlertToastActionType.Mute]: "Notifications muted",
  [MuteAlertToastActionType.Unmute]: "Notifications unmuted",
  [MuteAlertToastActionType.Error]: "Apologies",
};

const MuteAlertToast: FC<IMuteAlertToastProps> = ({ open, setOpen, action }) => {
  const dtFormatter = useSelector(selectSiteDateTimeFormatter);

  const getMessage = (action?: ToastAction) => {
    if (!action?.type) {
      return "";
    }

    switch (action.type) {
      case MuteAlertToastActionType.Mute:
        return `You won't receive notifications until \n${dtFormatter.formatDateAndTime(new Date(action.expirationDate))}`;
      case MuteAlertToastActionType.Unmute:
        return "You will receive notification as usual.";
      case MuteAlertToastActionType.Error:
        return action.errorMessage ?? "An error occurred";
    }
  };

  const handleClose = (_: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const title = action?.type ? ToastTitles[action.type] : "";
  const message = getMessage(action);
  const toastClass =
    action?.type === MuteAlertToastActionType.Error ? classes["fail-toast"] : classes["success-toast"];

  const toastAction = (
    <div className={classes["toast-container"]}>
      <div className={`${classes["toast-title"]} ${toastClass}`}>{title}</div>
      <div className={classes["toast-time-message"]}>{message}</div>
      <div className={classes["toast-btn"]}>
        <SolarGikButton text="OK" onClickFunc={handleClose} style={muteAlertToastButtonStyle} />
      </div>
    </div>
  );

  return (
    <SgBackdrop
      open={open}
      component={
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={null}
          onClose={handleClose}
          message=""
          action={toastAction}
          sx={muteAlertToastStyle}
        />
      }
    />
  );
};

export default MuteAlertToast;
