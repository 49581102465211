import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SgPopover from "../../../SolarGikLib/popover/SgPopover";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { IconCategory, ScheduleCommandIcons } from "../../../SolarGikLib/icons/IconsModels";
import { AppDispatch, RootState } from "../../app/Store";
import { fetchScheduledMaintenance } from "./scheduleCommandStore";
import ScheduledMaintenanceTable from "./ScheduledMaintenanceTable";
import classes from "./ExistsScheduledCommandPopOver.module.css";

interface IExistsScheduledCommandPopOverProps {
  siteId: string;
}

const ExistsScheduledCommandPopOver: FC<IExistsScheduledCommandPopOverProps> = ({ siteId }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const existsScheduledMaintenance = useSelector(
    (state: RootState) => state.scheduleCommand.existingScheduledMaintenance
  );
  const loading = useSelector((state: RootState) => state.scheduleCommand.loading);

  useEffect(() => {
    dispatch(fetchScheduledMaintenance(siteId));
  }, [siteId]);

  useEffect(() => {
    if (open) {
      dispatch(fetchScheduledMaintenance(siteId));
    }
  }, [open]);

  const Icon = getIcons(ScheduleCommandIcons.ExistsScheduledCommand, IconCategory.ScheduleCommand);

  const togglePopover = () => {
    setOpen(!open);
  };

  const isVisible = existsScheduledMaintenance && existsScheduledMaintenance.length > 0;

  return (
    <SgPopover
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={true}
      TriggerButton={
        <div className={`${classes["icon-container"]} ${isVisible ? "" : classes["icon-hidden"]}`}>
          <Icon onClick={togglePopover} />
        </div>
      }
      title={"Scheduled Manual Events"}
    >
      {
        <div className={classes["content-container"]}>
          <ScheduledMaintenanceTable
            scheduledMaintenance={existsScheduledMaintenance}
            loading={loading}
          />
        </div>
      }
    </SgPopover>
  );
};
export default ExistsScheduledCommandPopOver;
