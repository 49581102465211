import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridColDef } from "@mui/x-data-grid";
import { InfoOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Table from "../../../common/table/Table";
import { AppDispatch, RootState } from "../../app/Store";
import { fetchAlertsHistory } from "../../app/store/FaultsStore";
import useNumberOfRowsToDisplay from "../../../common/Hooks/useNumberOfRowsToDisplay";
import classes from "./Alerts.module.css";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import { selectSiteDateTimeFormatter, selectSiteId, selectSiteTrackersMap } from "../../sites/SiteStore";
import { AlertUnion } from "../Models";
import { getHistoryAlertTableColumns } from "./HistoryAlertTableColumns";
import AlertDetailsDialog from "./detailsDialog/AlertDetailsDialog";
import { useIs150PercentScreen } from "../../../common/WindowUtils";
import { LoadingState } from "../../app/LoadingState";
import MuteAlertCell from "./tableComponent/MuteAlertCell";
import { useMuteStatus } from "./hooks/useMuteStatus";

const rowsSurroundingHeight = 300;
const rowHeight = 45;

const AlertsHistory = () => {
  const dispatch = useDispatch<AppDispatch>();
  const pageSize = useNumberOfRowsToDisplay(rowsSurroundingHeight, rowHeight);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const [detailsAlarm, setDetailsAlarm] = useState<AlertUnion | undefined>();

  const siteId = useSelector(selectSiteId);
  useEffect(() => {
    const op = dispatch(fetchAlertsHistory({ siteId }));
    return () => op.abort();
  }, [siteId]);

  const loadingState = useSelector(
    (state: RootState) => state.faults.multiSitesFaults.alertsHistory[siteId]?.loadingState
  );
  const history = useSelector(
    (state: RootState) => state.faults.multiSitesFaults.alertsHistory[siteId]?.value
  );
  const alertsMetadata = useSelector(
    (state: RootState) => state.faults.multiSitesFaults.metadata.alerts
  );
  const dtFormatter = useSelector(selectSiteDateTimeFormatter);
  const trackerNamesMap = useSelector(selectSiteTrackersMap);
  const is150PercentScreen = useIs150PercentScreen();
  const getMuteStatus = useMuteStatus(siteId);

  const columnsDef = useMemo(
    () =>
      [
        ...getHistoryAlertTableColumns(alertsMetadata, trackerNamesMap, dtFormatter, is150PercentScreen),
        {
          field: "details",
          width: 74,
          headerName: "Details",
          sortable: false,
          filterable: false,
          renderCell: (params) => (
            <IconButton onClick={() => setDetailsAlarm(params.row)} size="small">
              <InfoOutlined />
            </IconButton>
          ),
        },
        {
          field: "Mute",
          headerName: "Mute",
          width: is150PercentScreen ? 70 : 90,
          renderCell: (params) => (
            <MuteAlertCell alertType={params.row.alertType} siteId={siteId} value={params.value} />
          ),
          valueGetter: (params) => getMuteStatus(params.row.alertType),
          filterable: false,
          disableColumnMenu: true,
          flex: 0.12,
        },
      ] as GridColDef<AlertUnion>[],
    [alertsMetadata, trackerNamesMap, dtFormatter, is150PercentScreen, siteId, getMuteStatus]
  );

  useEffect(() => {
    if (loadingState !== LoadingState.Error) {
      setAlertMessage(undefined);
      return;
    }
    setAlertMessage({
      text: "Failed to load alerts history",
      severity: "error",
    });
  }, [loadingState]);

  const handlePaginationChange = (params: { page: number; pageSize: number }) => {
    setCurrentPage(params.page + 1);
  };

  const tableData: AlertUnion[] = [
    ...(history?.aggregateAlerts.map(
      (value) => ({ ...value, kind: "aggregated", history: true }) as AlertUnion
    ) || []),
    ...(history?.singleDeviceAlerts.map(
      (value) => ({ ...value, kind: "single-device", history: true }) as AlertUnion
    ) || []),
  ];

  const isNoAlertsInSite = loadingState === LoadingState.Complete && tableData.length === 0;
  return (
    <>
      <AlertDetailsDialog
        alert={detailsAlarm}
        metadata={detailsAlarm ? alertsMetadata?.[detailsAlarm.alertType] : undefined}
        open={!!detailsAlarm}
        onClose={() => setDetailsAlarm(undefined)}
      />
      <div className={classes.container}>
        <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
        {isNoAlertsInSite && (
          <div className={classes["no-alerts-text"]}>
            <span className={`${TextEnum.h3}`}>
              There&apos;s no history of resolved alerts in this site...
            </span>
          </div>
        )}
        {!isNoAlertsInSite && loadingState !== LoadingState.Error && (
          <Table
            hideToolbar
            loading={loadingState === LoadingState.Pending}
            rows={tableData}
            columns={columnsDef}
            customUI={{
              "& .MuiDataGrid-main": {
                borderLeft: "0.3rem solid #63a7fd",
                borderRadius: "25px",
              },
            }}
            paginationMode="client"
            onPaginationModelChange={handlePaginationChange}
            paginationModel={{
              page: currentPage - 1,
              pageSize: pageSize,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "endTime", sort: "desc" }],
              },
            }}
            hideFooterPagination={tableData.length <= pageSize}
            getRowId={(alert: AlertUnion) => `${alert.alertType}_${alert.id}`}
            rowHeight={rowHeight}
            columnHeaderHeight={45}
            rowSelection={false}
          />
        )}
      </div>
    </>
  );
};

export default AlertsHistory;
