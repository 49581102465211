import { FC } from "react";

import { useSelector } from "react-redux";
import classes from "./HeaderDataPoint.module.css";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import DataPointInfo from "../data_point/DataPointInfo";
import TagInvalidIcon from "../data_point/TagInvalidIcon";
import DataPointTooltip from "../data_point/wrapper/DataPointTooltip";
import { RootState } from "../app/Store";
import { UserAccessType } from "../user/Models";
import FieldStateTooltip from "../data_point/wrapper/FieldStateTooltip";

interface FieldStateDataPointProps {
  tagName: string;
  siteId: string;
  titleOverride?: string;
  nameOverride?: string;
  valueToStringFuncOverride?: (value: number) => string;
  isBlinkingFunc?: (valueInString: string) => boolean;
}

const FieldStateDataPoint: FC<FieldStateDataPointProps> = ({
  tagName,
  siteId,
  titleOverride,
  nameOverride,
  valueToStringFuncOverride,
  isBlinkingFunc,
}) => {
  const userType = useSelector((state: RootState) => state.user.userType);
  const { title, valueInString, valueExist } = DataPointInfo(
    tagName,
    siteId,
    titleOverride,
    valueToStringFuncOverride
  );

  let isBlinking = false;
  if (valueExist && isBlinkingFunc != null) {
    isBlinking = isBlinkingFunc(valueInString);
  }

  const fieldStateDataPoint = (
    <div className={classes["data-point-container"]}>
      <TagInvalidIcon tagName={tagName} siteId={siteId} />
      <span className={TextEnum.h4}>
        {title}{" "}
        <span className={`${TextEnum.h4} ${isBlinking ? classes["blinking-class"] : ""}`}>
          {!valueExist ? NO_VALUE_PLACEHOLDER : valueInString}
        </span>
      </span>
    </div>
  );

  return userType >= UserAccessType.Engineer ? (
    <DataPointTooltip
      displayName={nameOverride}
      isShowDateOnly={false}
      isShowTagMetadataOnly={false}
      tagName={tagName}
      siteId={siteId}
    >
      {fieldStateDataPoint}
    </DataPointTooltip>
  ) : (
    <FieldStateTooltip siteId={siteId}>{fieldStateDataPoint}</FieldStateTooltip>
  );
};

export default FieldStateDataPoint;
