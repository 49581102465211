import React from "react";
import { FormControlLabel, Switch, Divider, Typography, Slider } from "@mui/material";
import classes from "./ForecastSafetySettingsEditor.module.css";

interface ForecastSafetySettingsEditorProps {
  forecastSafetyEnabled: boolean;
  toggleForecastSafety: () => void;
  forecastThreshold: number;
  updateForecastSafetyThreshold: (newValue: number) => void;
}

const ForecastSafetySettingsEditor: React.FC<ForecastSafetySettingsEditorProps> = ({
  forecastSafetyEnabled,
  toggleForecastSafety,
  forecastThreshold,
  updateForecastSafetyThreshold,
}) => {
  return (
    <div className={classes["forecast-safety-container"]}>
      <FormControlLabel
        key={"Forecast Safety"}
        control={<Switch checked={forecastSafetyEnabled} onChange={toggleForecastSafety} />}
        label={"Forecast Safety"}
      />
      <Divider orientation="vertical" flexItem />
      <div className={classes["forecast-threshold-container"]}>
        <Typography>
          Threshold: {forecastThreshold} <span className={classes["wind-gust-threshold-unit"]}>m/s</span>
        </Typography>
        <Slider
          value={forecastThreshold}
          onChange={(_, newValue) => updateForecastSafetyThreshold(newValue as number)}
          disabled={!forecastSafetyEnabled}
          min={0}
          max={30}
        />
      </div>
    </div>
  );
};

export default ForecastSafetySettingsEditor;
