import { useSelector } from "react-redux";
import { RootState } from "../../../app/Store";
import { MuteStatus, ALL_ALERTS_TYPE } from "../../Models";

export const useMuteStatus = (siteId: string) => {
  const subscribedAlerts = useSelector(
    (state: RootState) => state.faults.multiSitesFaults.subscribedAlerts[siteId]?.data
  );
  const mutedAlerts = useSelector(
    (state: RootState) => state.faults.multiSitesFaults.mutedAlerts[siteId]?.data
  );

  return (alertType: number): MuteStatus => {
    const isSubscribed = subscribedAlerts?.has(alertType) || subscribedAlerts?.has(ALL_ALERTS_TYPE);
    if (!isSubscribed) {
      return MuteStatus.Unsubscribed;
    }
    return mutedAlerts?.[alertType] ? MuteStatus.Muted : MuteStatus.Unmuted;
  };
};
