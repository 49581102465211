import { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Button, CSSObject, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { IAlertsSubscriber } from "../../faults/DTOs";
import { AppDispatch, RootState } from "../../app/Store";
import { removeAlertsSubscriber, updateAlertsSubscriber } from "../../app/store/AlertsSubscribersStore";
import classes from "./AlertsSubscribersGrid.module.css";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import EditToolbar from "./EditToolbar";
import { getTableColumns } from "./AlertsSubscribersGridColumns";
import { IUserDetails } from "../../user/Models";


export interface IAlertsSubscriberWithUserDetails {
  userDetails: IUserDetails;
  siteIds: string[];
  alertIds: number[];
}

export const TemporaryUserId = "initialUserId";

const gridBoxStyle: CSSObject = {
  height: 500,
  width: "70%",
  marginTop: "10px",
  "& .actions": {
    color: "text.secondary",
  },
  "& .textPrimary": {
    color: "text.primary",
  },
};

interface IAlertsSubscriberGridProps {
  subscribers: IAlertsSubscriber[];
  usersDetails: IUserDetails[];
}

const AlertsSubscribersGrid: FC<IAlertsSubscriberGridProps> = ({ subscribers, usersDetails }) => {
  const dispatch = useDispatch<AppDispatch>();

  const faultsMetadata = useSelector((state: RootState) => state.faults.multiSitesFaults.metadata);
  // These are all the sites that the logged in user is subcribed to
  // We assume that an Admin has access to All sites (siteIds = *) 
  // (and currently only admins has access to this page)
  const allSitesIds = useSelector((state: RootState) => state.user.siteIds);

  const [rows, setRows] = useState<IAlertsSubscriberWithUserDetails[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [alertMessage, setAlertMessage] = useState<AlertMessage>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<string>();
  const [sitesIds, setSitesIds] = useState<string[]>(["*", ...allSitesIds]);
  const [isAddingNewRow, setIsAddingNewRow] = useState<boolean>(false);

  useEffect(() => {
    setRows(
      subscribers
        .map(subscriber => {
          const userDetails = usersDetails.find(user => user.userId === subscriber.userId);
          return userDetails ? { userDetails: userDetails, alertIds: subscriber.alertIds, siteIds: subscriber.siteIds } : null;
        })
        .filter((row): row is IAlertsSubscriberWithUserDetails => row !== null)
    );
  }, [subscribers, usersDetails]);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.enterKeyDown
    ) {
      event.defaultMuiPrevented = true;
    }
    setRows((currentRows) =>
      currentRows.map((row) => (row.userDetails.userId === params.id ? { ...row, ...params.row } : { ...row }))
    );
  };

  const handleEditClick = (id: GridRowId) => () => {
    setAlertMessage(undefined);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    const row = rows.find((row) => row.userDetails.userId === id);
    if (!row) {
      return;
    }
    setSitesIds(row.userDetails.siteIds)
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const row = rows.find((row) => row.userDetails.userId === id);
    if (!row) {
      return;
    }
    if (row.userDetails.userId === TemporaryUserId) {
      setRows(rows.filter((row) => row.userDetails.userId !== id));
      return;
    }
    setSelectedRowId(id as string);
    setDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedRowId !== undefined) {
      setSelectedRowId(undefined);
      dispatch(removeAlertsSubscriber(selectedRowId));
    }
    setDialogOpen(false);
  };

  const handleCancelDelete = () => {
    setSelectedRowId(undefined);
    setDialogOpen(false);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    if (id === TemporaryUserId) {
      setRows(rows.filter((row) => row.userDetails.userId !== id));
      setIsAddingNewRow(false)
    }
  };

  const processRowUpdate = async (newRow: GridRowModel, originalRow: GridRowModel) => {
    const updatedRow = newRow as IAlertsSubscriberWithUserDetails;
    const isNewRow = originalRow.userDetails.userId === TemporaryUserId;
    const action = isNewRow ? "create" : "update";

    const actionResult = await dispatch(updateAlertsSubscriber({
      ...updatedRow,
      userId: updatedRow.userDetails.userId,
    }));

    if (updateAlertsSubscriber.rejected.match(actionResult)) {
      throw new Error(`Failed to ${action} subscriber`);
    }

    if (isNewRow && isAddingNewRow) {
      setIsAddingNewRow(false);
    }
    setAlertMessage({
      text: `Subscriber ${action}d successfully`,
      severity: "success",
    });
    return updatedRow;
  };

  const handleProcessRowUpdateError = (error: Error) => {
    setAlertMessage({
      text: error.message,
      severity: "error",
    });
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const tableColumns: GridColDef<IAlertsSubscriberWithUserDetails>[] = [
    ...getTableColumns(rows, usersDetails, sitesIds, setSitesIds, allSitesIds, isAddingNewRow, faultsMetadata.alerts),
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerAlign: "center",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={id}
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={id}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div className={classes["container"]}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      <Box sx={gridBoxStyle}>
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": {
              textAlign: "center",
              justifyContent: "center",
              borderRight: "1px solid var(--table-outline-color)",
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: "1px solid var(--table-outline-color)",
            }
          }}
          rows={rows}
          columns={tableColumns}
          getRowId={(row) => row.userDetails.userId}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          slots={{ toolbar: EditToolbar }}
          slotProps={{
            toolbar: { setRows, setRowModesModel, setIsAddingNewRow },
          }}
          localeText={{
            noRowsLabel: "No subscribers found",
          }}
          rowSelection={false}
        />
        <Dialog open={dialogOpen} onClose={handleCancelDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>Are you sure you want to delete this subscriber?</DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="error">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default AlertsSubscribersGrid;
