import { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIos";
import classes from "./StoryCard.module.css";
import { selectSiteId } from "../../features/sites/SiteStore";
import { PageNames } from "../../pages/PageNames";
import SolarGikButton from "../Button";
interface IStoryCardProp {
  children?: React.ReactNode;
  title?: string;
  navigationLink?: IStoryCardNavigationLinkProp;
}

interface IStoryCardNavigationLinkProp {
  text: string;
  targetPage: PageNames;
  beforeNavigate?: () => void;
}

const StoryCardNavigationLink: FC<IStoryCardNavigationLinkProp> = ({
  text,
  targetPage,
  beforeNavigate,
}) => {
  const siteId = useSelector(selectSiteId);
  const navigate = useNavigate();
  const target = "/" + encodeURI(siteId) + "/" + targetPage;
  return (
    <SolarGikButton
      icon={<ArrowForwardIosRoundedIcon style={{ fontSize: "10px", color: "black" }} />}
      onClickFunc={() => {
        beforeNavigate?.();
        navigate(target);
      }}
      text={text}
      style={{ color: "var(--story-dashboard-blue)" }}
    ></SolarGikButton>
  );
};

const StoryCard: FC<IStoryCardProp> = ({ title, children, navigationLink }) => {
  return (
    <div className={classes["wrapper"]}>
      <div className={classes["container"]}>
        <div className={classes["header"]}>
          {title && <span className={`${classes["title"]}`}>{title}</span>}
          {navigationLink && <StoryCardNavigationLink {...navigationLink} />}
        </div>
        <div className={`${classes["content"]}`}>{children}</div>
      </div>
    </div>
  );
};

export default StoryCard;
