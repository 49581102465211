import { FC, useState, MouseEvent, useEffect } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { deleteScheduleMaintenance, clearDeleteError } from "./scheduleCommandStore";
import {
  DayOfWeek,
  dayOfWeekToStringMap,
  dayOfWeekValues,
  IScheduledMaintenance,
  ScheduledMaintenanceType,
} from "./Models";
import Table from "../../../common/table/Table";
import Selector from "../../../SolarGikLib/fields/Selector";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import {
  FileCommandsIcons,
  IconCategory,
  ScheduleCommandIcons,
} from "../../../SolarGikLib/icons/IconsModels";
import getIcons from "../../../SolarGikLib/icons/Icons";
import classes from "./ScheduledMaintenanceTable.module.css";
import { createFormattedCell } from "../../../common/table/CellFactory";
import { AppDispatch, RootState } from "../../app/Store";
import CellScheduledMaintenanceTime from "./CellScheduledMaintenanceTime";
import { TrackerBadges } from "./TrackerBadges";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import { bitFlagToDayOfWeekEnum, formatMaintenanceTime } from "./utils";

interface ScheduledMaintenanceTableProps {
  scheduledMaintenance: IScheduledMaintenance[];
  loading: boolean;
}

const ScheduledMaintenanceTable: FC<ScheduledMaintenanceTableProps> = ({
  scheduledMaintenance,
  loading,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const siteTimezone = useSelector((state: RootState) => state.site.ianaTimeZoneName);
  const deleteErrorMessage = useSelector((state: RootState) => state.scheduleCommand.deleteErrorMessage);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();

  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedMaintenanceId, setSelectedMaintenanceId] = useState<string | null>(null);

  useEffect(() => {
    if (deleteErrorMessage) {
      setAlertMessage({
        severity: "error",
        text: deleteErrorMessage,
      });
      dispatch(clearDeleteError());
    }
  }, [deleteErrorMessage]);

  const recurrenceIcon = getIcons(ScheduleCommandIcons.Recurrence, IconCategory.ScheduleCommand);
  const deleteIcon = getIcons(FileCommandsIcons.Delete, IconCategory.FileCommands);

  const activeScheduledCommandIcon = getIcons(
    ScheduleCommandIcons.ActiveScheduledCommand,
    IconCategory.ScheduleCommand
  );

  const handleMouseEnter = (event: MouseEvent<HTMLDivElement>) => {
    const row = event.currentTarget.getAttribute("data-id");
    if (row) {
      setHoveredRow(row);
    }
  };

  const handleDeleteClick = (maintenanceId: string) => {
    setSelectedMaintenanceId(maintenanceId);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedMaintenanceId && siteId) {
      dispatch(
        deleteScheduleMaintenance({
          siteId,
          scheduledId: selectedMaintenanceId,
        })
      );
    }
    setDeleteModalOpen(false);
  };

  return (
    <>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />

      <div className={`${classes["table-container"]}${TextEnum.h6}`}>
        <Table
          columns={ScheduleCommandTableColumns(
            siteTimezone,
            hoveredRow,
            handleDeleteClick,
            recurrenceIcon,
            deleteIcon,
            activeScheduledCommandIcon
          )}
          rows={scheduledMaintenance}
          hideFooterSelectedRowCount
          hideToolbar={true}
          loading={loading}
          getRowId={(row: IScheduledMaintenance) => row.id ?? "loading"}
          slots={{
            noRowsOverlay: NoRowsOverlay,
          }}
          sx={
            scheduledMaintenance.length === 0
              ? {
                  height: "20vh",
                }
              : undefined
          }
          slotProps={{
            row: {
              onMouseEnter: handleMouseEnter,
              onMouseLeave: () => setHoveredRow(null),
            },
          }}
        />
      </div>

      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this scheduled maintenance?</DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScheduledMaintenanceTable;

export const ScheduleCommandTableColumns = (
  siteTimezone: string,
  hoveredRow: string | null,
  handleDeleteClick: (maintenanceId: string) => void,
  RecurrenceIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  DeleteIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  ActiveScheduledCommandIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
): GridColDef<IScheduledMaintenance>[] => {
  return [
    {
      field: "scheduleType",
      headerName: "",
      flex: 0.1,
      renderCell: (params) => {
        const isScheduledActive =
          dayjs().isAfter(params.row.startTimeUtc) && dayjs().isBefore(params.row.endTimeUtc);

        return (
          <>
            {isScheduledActive && (
              <div className={classes["active-icon-container"]}>
                <ActiveScheduledCommandIcon />
              </div>
            )}
            {params.value === ScheduledMaintenanceType.Recurring ? (
              <RecurrenceIcon />
            ) : (
              <div className={`${classes["one-time"]}`}>
                <span>One</span>
                <span>Time</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "startTimeUtc",
      headerName: "From - To",
      headerAlign: "left",
      flex: 0.7,
      ...createFormattedCell((value) => ({
        value: value.startTimeUtc,
        formatted: formatMaintenanceTime(value, siteTimezone),
      })),
      renderCell: (params) => {
        const isHovered = params.row.id === hoveredRow;
        return (
          <div className={classes["form-to-container"]}>
            <div
              className={`${classes["delete-icon-container"]} ${isHovered ? classes["show-delete-icon"] : ""}`}
            >
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(params.row.id);
                }}
              />
            </div>
            <div className={classes["time-container"]}>
              <CellScheduledMaintenanceTime value={params.row} siteTimezone={siteTimezone} />
            </div>
          </div>
        );
      },
    },
    {
      field: "recurrence",
      headerAlign: "left",
      headerName: "Recurrence",
      flex: 0.6,
      renderCell: (params) => {
        if (params.value) {
          const { repeatEveryWeek, daysInWeek } = params.value;
          return (
            <div className={classes["align-start"]}>
              <span>{`Every ${repeatEveryWeek} Week`}</span>
              <span className={classes["recurrence-icon"]}>
                <RecurrenceIcon />
              </span>
              <Selector<DayOfWeek>
                title=""
                values={dayOfWeekValues}
                multiSelect={true}
                value={bitFlagToDayOfWeekEnum(daysInWeek)}
                convertValueToString={(value) => dayOfWeekToStringMap[value]}
              />
            </div>
          );
        }
        return <div className={classes["align-start"]}>One Time</div>;
      },
    },
    {
      field: "trackerIds",
      headerAlign: "left",
      headerName: "Trackers",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className={classes["align-start"]}>
            <TrackerBadges trackerIds={params.value} />
          </div>
        );
      },
    },
    {
      field: "reason",
      headerName: "Reason",
      headerAlign: "left",
      flex: 0.4,
      renderCell(params) {
        return <div className={classes["align-start"]}>{params.value}</div>;
      },
    },
    {
      field: "targetElevation",
      headerName: "Target Elevation",
      headerAlign: "left",
      flex: 0.3,
      renderCell: (params) => renderCellToAlignStart(params),
    },
    {
      field: "createdBy",
      headerName: "By",
      headerAlign: "left",
      flex: 0.4,
      renderCell: (params) => renderCellToAlignStart(params),
    },
  ];
};

const renderCellToAlignStart = (params: GridRenderCellParams) => {
  return <div className={classes["align-start"]}>{params.value}</div>;
};
const NoRowsOverlay = () => <div className={classes["no-rows"]}>No scheduled maintenance</div>;
