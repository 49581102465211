import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridColDef } from "@mui/x-data-grid";
import Table from "../../../common/table/Table";
import { AppDispatch, RootState } from "../../app/Store";
import getActiveAlertTableColumns from "./ActiveAlertTableColumns";
import useNumberOfRowsToDisplay from "../../../common/Hooks/useNumberOfRowsToDisplay";
import classes from "./Alerts.module.css";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import { selectSiteDateTimeFormatter, selectSiteId, selectSiteTrackersMap } from "../../sites/SiteStore";
import { fetchActiveAlerts } from "../../app/store/FaultsStore";
import { AlertUnion } from "../Models";
import AlertDetailsDialog from "./detailsDialog/AlertDetailsDialog";
import { useIs150PercentScreen } from "../../../common/WindowUtils";
import { LoadingState } from "../../app/LoadingState";
import AlertNameCell from "./tableComponent/AlertNameCell";
import MuteAlertCell from "./tableComponent/MuteAlertCell";
import { useMuteStatus } from "./hooks/useMuteStatus";

const rowsSurroundingHeight = 276;
const rowHeight = 45;
const refreshIntervalMs = 60_000;

const ActiveAlerts = () => {
  const pageSize = useNumberOfRowsToDisplay(rowsSurroundingHeight, rowHeight);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const [detailsAlarm, setDetailsAlarm] = useState<AlertUnion | undefined>();
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const siteId = useSelector(selectSiteId);
  const siteAlerts = useSelector(
    (state: RootState) => state.faults.multiSitesFaults.activeFaults[siteId]?.alerts
  );
  const alertsMetadata = useSelector(
    (state: RootState) => state.faults.multiSitesFaults.metadata.alerts
  );

  const dispatch = useDispatch<AppDispatch>();
  const getMuteStatus = useMuteStatus(siteId);

  useEffect(() => {
    if (!siteId) {
      return;
    }
    dispatch(fetchActiveAlerts(siteId));
    const interval = setInterval(() => dispatch(fetchActiveAlerts(siteId)), refreshIntervalMs);
    return () => clearInterval(interval);
  }, [siteId]);
  useEffect(() => {
    if (siteAlerts?.loadingState != LoadingState.Error) {
      setAlertMessage(undefined);
      return;
    }
    setAlertMessage({
      text: "Failed to load alerts",
      severity: "error",
    });
  }, [siteAlerts]);

  const handlePaginationChange = (params: { page: number; pageSize: number }) => {
    setCurrentPage(params.page + 1);
  };

  const tableData: AlertUnion[] = useMemo(
    () => [
      ...(siteAlerts?.data?.aggregatedAlerts.map(
        (alert) => ({ ...alert, kind: "aggregated" }) as AlertUnion
      ) || []),
      ...(siteAlerts?.data?.deviceAlerts.map(
        (alert) => ({ ...alert, kind: "single-device" }) as AlertUnion
      ) || []),
    ],
    [siteAlerts]
  );
  const trackerNamesMap = useSelector(selectSiteTrackersMap);
  const dtFormatter = useSelector(selectSiteDateTimeFormatter);
  const is150PercentScreen = useIs150PercentScreen();

  const columnsDef = useMemo(
    () =>
      [
        {
          field: "name",
          headerName: "Name",
          flex: 0.8,
          sortable: false,
          renderCell: (params) => (
            <AlertNameCell
              alert={params.row}
              metadata={alertsMetadata}
              onSetDetailsAlarm={setDetailsAlarm}
              isHovered={hoveredRow === `${params.row.alertType}_${params.row.id}`}
            />
          ),
        },
        ...getActiveAlertTableColumns(alertsMetadata, trackerNamesMap, dtFormatter, is150PercentScreen),
        {
          field: "Mute",
          headerName: "Mute",
          width: is150PercentScreen ? 70 : 90,
          renderCell: (params) => (
            <MuteAlertCell alertType={params.row.alertType} siteId={siteId} value={params.value} />
          ),
          valueGetter: (params) => getMuteStatus(params.row.alertType),
          filterable: false,
          disableColumnMenu: true,
          flex: 0.12,
        },
      ] as GridColDef<AlertUnion>[],
    [alertsMetadata, trackerNamesMap, dtFormatter, is150PercentScreen, siteId, getMuteStatus]
  );

  const noAlerts = tableData.length == 0 && siteAlerts?.loadingState === LoadingState.Complete;
  return (
    <>
      <AlertDetailsDialog
        alert={detailsAlarm}
        metadata={detailsAlarm ? alertsMetadata?.[detailsAlarm.alertType] : undefined}
        open={!!detailsAlarm}
        onClose={() => setDetailsAlarm(undefined)}
      />
      <div className={classes.container}>
        <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
        {noAlerts && (
          <div className={classes["no-alerts-text"]}>
            <span className={`${TextEnum.h3}`}>Congratulations!</span>
            <span className={`${TextEnum.h2}`}> No alerts in the site!</span>
          </div>
        )}
        {!noAlerts && siteAlerts?.loadingState !== LoadingState.Error && (
          <Table
            hideToolbar
            loading={siteAlerts?.loadingState !== LoadingState.Complete}
            rows={tableData}
            columns={columnsDef}
            customUI={{
              "& .MuiDataGrid-main": {
                borderLeft: "0.3rem solid #63a7fd",
                borderRadius: "25px",
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "startTime", sort: "desc" }],
              },
            }}
            paginationMode="client"
            onPaginationModelChange={handlePaginationChange}
            paginationModel={{
              page: currentPage - 1,
              pageSize: pageSize,
            }}
            hideFooterPagination={tableData.length <= pageSize}
            getRowId={(alert: AlertUnion) => `${alert.alertType}_${alert.id}`}
            rowHeight={rowHeight}
            columnHeaderHeight={45}
            rowSelection={false}
            slotProps={{
              row: {
                onMouseEnter: (event) => {
                  const id = event.currentTarget.getAttribute("data-id");
                  if (id) {
                    setHoveredRow(id);
                  }
                },
                onMouseLeave: () => setHoveredRow(null),
              },
            }}
          />
        )}
      </div>
    </>
  );
};

export default ActiveAlerts;
