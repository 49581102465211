import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import {
  IAddScheduledMaintenanceDto,
  IScheduledMaintenance,
  IScheduledMaintenanceDto,
  ScheduledMaintenanceType,
  IScheduledMaintenanceOverlapResult,
  IScheduledMaintenanceRecurringInput,
  IScheduledMaintenanceOneTimeInput,
} from "./Models";
import {
  getExistingScheduledMaintenance,
  deleteScheduledMaintenance,
  addScheduledMaintenance,
} from "./API";

interface ScheduleCommandState {
  oneTimeScheduleData: IScheduledMaintenanceOneTimeInput;
  recurringScheduleData: IScheduledMaintenanceRecurringInput;
  oneTimeError: string | null;
  recurringError: string | null;
  existingScheduledMaintenance: IScheduledMaintenance[];
  loading: boolean;
  deleteErrorMessage?: string;
  conflictsScheduledMaintenance?: IScheduledMaintenanceOverlapResult[];
  siteId: string;
}
const now = new Date().getTime();
const initialState: ScheduleCommandState = {
  oneTimeScheduleData: {
    startDateTimeUtc: now,
    endDateTimeUtc: now,
  },
  recurringScheduleData: {
    repeatEveryWeek: 1,
    selectedDays: [],
    startTime: "",
    endTime: "",
    fromDateUtc: now,
    untilDateUtc: now,
  },
  oneTimeError: "",
  recurringError: "",
  existingScheduledMaintenance: [],
  loading: false,
  deleteErrorMessage: undefined,
  siteId: "",
};

const ScheduleCommandSlice = createSlice({
  name: "scheduleCommand",
  initialState,
  reducers: {
    setOneTimeScheduleData(state, action: PayloadAction<IScheduledMaintenanceOneTimeInput>) {
      state.oneTimeScheduleData = action.payload;
      state.oneTimeError = null;
    },
    setRecurringScheduleData(state, action: PayloadAction<IScheduledMaintenanceRecurringInput>) {
      state.recurringScheduleData = action.payload;
      state.recurringError = null;
    },
    setOneTimeError(state, action: PayloadAction<string>) {
      state.oneTimeError = action.payload;
    },
    setRecurringError(state, action: PayloadAction<string>) {
      state.recurringError = action.payload;
    },
    clearDeleteError(state) {
      state.deleteErrorMessage = undefined;
    },
    clearConflicts(state) {
      state.conflictsScheduledMaintenance = undefined;
    },
    setScheduleOneTimeError: (state, action: PayloadAction<string | null>) => {
      state.oneTimeError = action.payload;
    },
    setScheduleRecurringError: (state, action: PayloadAction<string | null>) => {
      state.recurringError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScheduledMaintenance.fulfilled, (state, action) => {
      const data = action.payload?.map((item) => mapScheduledMaintenanceDtoToScheduledMaintenance(item));
      state.existingScheduledMaintenance = data;
      state.loading = false;
    });
    builder.addCase(fetchScheduledMaintenance.pending, (state, action) => {
      const newSiteId = action.meta.arg;
      if (state.siteId !== newSiteId) {
        state.existingScheduledMaintenance = [];
      }
      state.siteId = newSiteId;
      state.loading = true;
    });
    builder.addCase(fetchScheduledMaintenance.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteScheduleMaintenance.rejected, (state) => {
      state.deleteErrorMessage = "Failed to delete scheduled maintenance. Please try again later.";
    });

    builder.addCase(addScheduleMaintenance.pending, (state) => {
      state.conflictsScheduledMaintenance = undefined;
    });

    builder.addCase(addScheduleMaintenance.rejected, (state, action) =>
      handleAddScheduleMaintenanceRejected(state, action.payload)
    );
  },
});

const handleAddScheduleMaintenanceRejected = (
  state: ScheduleCommandState,
  payload?: {
    conflicts?: IScheduledMaintenanceOverlapResult[];
    message?: string;
  }
) => {
  if (payload?.conflicts) {
    state.conflictsScheduledMaintenance = payload.conflicts;
  }
};

export const fetchScheduledMaintenance = createAsyncThunk(
  "scheduleCommand/fetchScheduledMaintenance",
  async (siteId: string) => {
    return await getExistingScheduledMaintenance(siteId);
  }
);

export const deleteScheduleMaintenance = createAsyncThunk(
  "scheduleCommand/deleteScheduleMaintenance",
  async ({ siteId, scheduledId }: { siteId: string; scheduledId: string }, { dispatch }) => {
    await deleteScheduledMaintenance(siteId, scheduledId);
    dispatch(fetchScheduledMaintenance(siteId));
    return scheduledId;
  }
);

export const addScheduleMaintenance = createAsyncThunk<
  IScheduledMaintenanceDto,
  { ianaTimeZone: string; scheduledData: IAddScheduledMaintenanceDto },
  {
    rejectValue: {
      conflicts?: IScheduledMaintenanceOverlapResult[];
      message?: string;
    };
  }
>(
  "scheduleCommand/addScheduleMaintenance",
  async ({ ianaTimeZone, scheduledData }, { rejectWithValue, dispatch }) => {
    try {
      const response = await addScheduledMaintenance(ianaTimeZone, scheduledData);
      dispatch(fetchScheduledMaintenance(scheduledData.siteId));
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          return rejectWithValue({
            conflicts: error.response.data,
          });
        }
        return rejectWithValue({
          message:
            error.response?.data?.message || "Failed to add scheduled maintenance. Please try again",
        });
      }
      return rejectWithValue({
        message: "An unexpected error occurred. Please try again.",
      });
    }
  }
);

const mapScheduledMaintenanceDtoToScheduledMaintenance = (
  item: IScheduledMaintenanceDto
): IScheduledMaintenance => {
  return {
    ...item,
    scheduleType: item.recurrence
      ? ScheduledMaintenanceType.Recurring
      : ScheduledMaintenanceType.OneTime,
  };
};

export const {
  setOneTimeScheduleData,
  setRecurringScheduleData,
  setOneTimeError,
  setRecurringError,
  clearDeleteError,
  clearConflicts,
  setScheduleOneTimeError,
  setScheduleRecurringError,
} = ScheduleCommandSlice.actions;

export const scheduleCommandReducer = ScheduleCommandSlice.reducer;
