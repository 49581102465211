import { FC } from "react";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import classes from "./SolarGikToast.module.css";
import SolarGikButton from "../Button";
import { toastButtonStyle, toastStyle } from "./SolarGikToastStyle";
import SgBackdrop from "../backdrop/SgBackdrop";
import { ISolarGikToastProps, ToastType } from "./SolarGikToastModels";

const SolarGikToast: FC<ISolarGikToastProps> = ({
  open,
  onClose,
  title,
  message,
  type = ToastType.Success,
  autoHideDuration = null,
  buttonText = "OK",
}) => {
  const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };

  const toastAction = (
    <div className={classes["toast-container"]}>
      <div
        className={`${classes["toast-title"]} ${type === ToastType.Success ? classes["success-toast"] : classes["error-toast"]}`}
      >
        {title}
      </div>
      <div className={classes["toast-message"]}>{message}</div>
      <div className={classes["toast-btn"]}>
        <SolarGikButton text={buttonText} onClickFunc={handleClose} style={toastButtonStyle} />
      </div>
    </div>
  );

  const snackbar = (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      message=""
      action={toastAction}
      sx={toastStyle}
    />
  );

  return <SgBackdrop open={open} component={snackbar} />;
};

export default SolarGikToast;
