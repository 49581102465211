export type AlertTransition = ISingleDeviceAlertTransition | IAggregatedAlertTransition;

export enum UrgencyLevel {
  Closed = 0,
  Low = 10,
  Medium = 20,
  High = 30,
}

export enum ComponentType {
  Inverter = 1,
  Tracker = 2,
  System = 3,
  WindSensor = 4,
  SnowSensor = 5,
  RadiationSensor = 6,
  PowerMeter = 7,
}

interface IScreeningTimes {
  low?: number;
  medium?: number;
  high?: number;
}

export interface IFaultsMetadata {
  issues: IIssueMetadata[];
  alerts: IAlertMetadata[];
}

export interface IIssueMetadata {
  issueType: number;
  name: string;
  openStateDescription: string;
  closedStateDescription: string;
  troubleshootingDescription: string;
  componentType: ComponentType;
  screeningTimesMinutes?: IScreeningTimes;
}

export interface IAlertMetadata {
  alertType: number;
  name: string;
  openStateDescription: string;
  closedStateDescription: string;
  componentType: ComponentType;
  screeningTimesMinutes: IScreeningTimes;
}

export interface ISitesIssueCounters {
  [siteId: string]: {
    highSeverity: number;
    mediumSeverity: number;
    lowSeverity: number;
  };
}

export interface ISingleDeviceIssue {
  id: number;
  issueType: number;
  deviceId: number;
  startTime: number;
  urgencyLevel: UrgencyLevel;
}

export interface ISiteActiveAlerts {
  deviceAlerts: ISingleDeviceAlert[];
  aggregatedAlerts: IAggregateAlert[];
}

export interface IAlertsHistory {
  singleDeviceAlerts: ISingleDeviceAlertHistory[];
  aggregateAlerts: IAggregateAlertHistory[];
}

export interface ISingleDeviceAlert {
  id: number;
  alertType: number;
  deviceId: number;
  startTime: number;
  urgencyLevel: UrgencyLevel;
  additionalInfo?: number;
}

export interface ISingleDeviceAlertHistory extends ISingleDeviceAlert {
  id: number;
  endTime?: number;
}

export interface IAggregateAlert {
  id: number;
  alertType: number;
  deviceIds: number[];
  startTime: number;
  urgencyLevel: UrgencyLevel;
  errorFraction: number;
}

export interface IAggregateAlertHistory extends IAggregateAlert {
  endTime?: number;
}

export interface ISingleDeviceIssueTransition {
  deviceId: number;
  oldUrgencyLevel: UrgencyLevel;
  newUrgencyLevel: UrgencyLevel;
  transitionTime: number;
}

export interface ISingleDeviceAlertTransition {
  deviceId: number;
  oldUrgencyLevel: UrgencyLevel;
  newUrgencyLevel: UrgencyLevel;
  transitionTime: number;
  additionalInfo?: number;
}

export interface IAggregatedAlertTransition {
  removedDeviceIds: number[];
  addedDeviceIds: number[];
  oldUrgencyLevel: UrgencyLevel;
  newUrgencyLevel: UrgencyLevel;
  errorFraction: number;
  transitionTime: number;
}

export enum NotificationChannel {
  Email = 0,
  SlackChannel = 1,
}

export interface NotificationEndpointDetails {
  emailAddress?: string;
}

export interface IAlertsSubscriber {
  userId: string;
  siteIds: string[];
  alertIds: number[];
}

export enum SafeReason {
  SgEngineer = 1,
  PortalUser = 2,
  SystemWindProtection = 3,
  SystemSnowProtection = 4,
  SystemWindAndSnowProtection = 5,
  SystemUps = 6,
}

export interface IMuteAlert {
  siteId: string;
  alertType: number;
  expirationDays: number;
}
