import { Dispatch, SetStateAction } from "react";
import { Box, Chip } from "@mui/material";
import { GridColDef, GridPreProcessEditCellProps, GridRenderEditCellParams } from "@mui/x-data-grid";
import { IAlertsSubscriberWithUserDetails } from "./AlertsSubscribersGrid";
import { IAlertMetadataMap } from "../../faults/Models";
import { IUserDetails } from "../../user/Models";
import LightTooltipOnEllipsis from "../../../SolarGikLib/tooltip/LightTooltipOnEllipsis";
import classes from "./AlertsSubscribersGridColumns.module.css";
import MultiSelectEditCell from "./MultiSelectEditCell";
import UserDetailsEditCell from "./UserDetailsEditCell";


const allAlertsWildCard = -1

const getAlertsNames = (alertsMetadata?: IAlertMetadataMap): string[] => {
    return Object.values(alertsMetadata ?? []).map((alert) => alert.name);
};

const extractAlertNames = (alertIds: number[], alertsMetadata?: IAlertMetadataMap): string[] => {
    if (alertIds.includes(allAlertsWildCard)) {
        return ["All"]
    }
    return alertIds.map(alertId => alertsMetadata?.[alertId]?.name ?? "Unknown")
};

const getAlertId = (name: string, alertsMetadata?: IAlertMetadataMap): number => {
    if (name == "All") {
        return allAlertsWildCard;
    }
    return Object.values(alertsMetadata ?? []).find((alert) => alert.name === name)
        ?.alertType;
};

interface IMultiSelectCellTooltipProps {
    titleContent: string[]
    children: React.ReactElement;
}

const MultiSelectCellTooltip = ({ titleContent, children }: IMultiSelectCellTooltipProps) => {
    return <LightTooltipOnEllipsis
        title={<span className={classes["tooltip-title"]}>
            {titleContent.join("\n")}
        </span>}>
        {children}
    </LightTooltipOnEllipsis>

};

const preProcessMultiSelectCell = (params: GridPreProcessEditCellProps) => {
    const hasError = params.props.value.length == 0;
    return { ...params.props, error: hasError };
}

export const getTableColumns = (
    rows: IAlertsSubscriberWithUserDetails[],
    usersDetails: IUserDetails[],
    sitesIds: string[],
    setSitesIds: Dispatch<SetStateAction<string[]>>,
    allSitesIds: string[],
    isAddingNewRow: boolean,
    alertsMetadata?: IAlertMetadataMap
): GridColDef<IAlertsSubscriberWithUserDetails>[] =>
    [
        {
            field: "userDetails",
            headerName: "Email",
            headerAlign: "center",
            width: 200,
            flex: 0.7,
            valueGetter: (params) => params.row.userDetails.email,
            valueSetter: (params) => {
                const { value, row } = params;
                return { ...row, userDetails: value };
            },
            editable: isAddingNewRow,
            renderEditCell: (props) =>
                <UserDetailsEditCell params={props} rows={rows} usersDetails={usersDetails} setSitesIds={setSitesIds} />
        },
        {
            field: "siteIds",
            headerName: "Sites",
            headerAlign: "center",
            flex: 0.75,
            width: 100,
            valueGetter: (params) => (params.row.siteIds.includes("*") ? ["All"] : params.row.siteIds),
            renderCell: (params) =>
                <MultiSelectCellTooltip titleContent={params.value}>
                    <span className={classes["sites-cell"]}>
                        {params.value.join(", ")}
                    </span>
                </MultiSelectCellTooltip>,
            preProcessEditCellProps: preProcessMultiSelectCell,
            valueSetter: (params) => {
                const { value, row } = params;
                const rowsSiteIds = value.includes("All") ? ["*"] : value;
                return { ...row, siteIds: rowsSiteIds };
            },
            editable: true,
            renderEditCell: (props: GridRenderEditCellParams) => {
                const options = sitesIds.includes("*") ? ["All", ...allSitesIds] : [...sitesIds];
                return <MultiSelectEditCell params={props} options={options} />;
            }
        },
        {
            field: "alertIds",
            headerName: "Alerts",
            headerAlign: "center",
            flex: 1,
            width: 200,
            valueGetter: (params) => extractAlertNames(params.row.alertIds, alertsMetadata),
            valueSetter: (params) => {
                const { value, row } = params;
                const alertIds = value.map((alertName: string) => getAlertId(alertName, alertsMetadata));
                return { ...row, alertIds: alertIds };
            },
            renderCell: (params) =>
                <MultiSelectCellTooltip titleContent={params.value}>
                    <Box sx={{ display: 'flex', gap: 0.5, minWidth: 0 }}>
                        {params.value.map((value: string) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                </MultiSelectCellTooltip>,
            editable: true,
            preProcessEditCellProps: preProcessMultiSelectCell,
            renderEditCell: (props: GridRenderEditCellParams) => {
                const options = ["All", ...getAlertsNames(alertsMetadata)];
                return <MultiSelectEditCell params={props} options={options} />;
            }
        },
    ];