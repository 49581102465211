import { CSSObject } from "@mui/material/styles";

export const muteDaysPopoverStyle = {
  "& .MuiPopover-paper": {
    borderRadius: "var(--card-border-radius)",
    padding: "12px 16px",
    minWidth: "300px",
    marginTop: "var(--spacing-16)",
  },
};

export const muteDaysButtonStyle = {
  borderRadius: "50px",
  minWidth: "80px",
  height: "40px",
  backgroundColor: "var(--blue-button-background-color)",
  color: "white",
  "&:hover": {
    backgroundColor: "var(--filled-button-on-hover-color)",
  },
  padding: "8px 20px",
  textTransform: "none",
  fontSize: "14px",
  fontWeight: "500",
  boxShadow: "none",
  marginLeft: "10px",
};

export const muteDaysInputStyle = {
  width: "230px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    height: "40px",
  },
  "& .MuiInputBase-input": {
    paddingLeft: "16px",
  },
  "& .MuiFormHelperText-root": {
    position: "absolute",
    bottom: "-24px",
    marginLeft: "10px",
    padding: "4px 0",
    fontSize: "12px",
    fontWeight: "500",
    color: "#FF4E4E",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#CCCCCC",
    opacity: 1,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ccc",
  },
};

export const muteDaysArrowStyle: CSSObject = {
  position: "absolute",
  width: 0,
  height: 0,
  borderLeft: "8px solid transparent",
  borderRight: "8px solid transparent",
  borderBottom: "8px solidrgb(202, 170, 170)",
  top: -8,
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 1000,
};
