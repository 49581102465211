import { useEffect } from "react";

import { EndSessionRequest } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "./UserStore";
import { AppDispatch, RootState } from "../app/Store";
import { UserInitializationState } from "./Models";

const useSetupUserInfo = () => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch<AppDispatch>();
  const account = useAccount(accounts[0] || {});
  const { initializationState } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (initializationState === UserInitializationState.Initialized) {
      return;
    }
    if (!account?.name || account.name.length <= 0) {
      console.error("User does not have a name.");
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      } as EndSessionRequest);
      return;
    }
    const dispatchedAction = dispatch(fetchUserData(account.name));
    return () => dispatchedAction.abort();
  }, [account]);
};

export default useSetupUserInfo;
