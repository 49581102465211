import { UserSafeActivationReason, IEmergencyListitem } from "./EmergencyModel";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { ReasonIcons, IconCategory } from "../../../SolarGikLib/icons/IconsModels";
import { UserAccessType } from "../../user/Models";
const emergencyOptionListConfig: IEmergencyListitem[] = [
  {
    title: "Wind Safe",
    icon: getIcons(ReasonIcons.Wind, IconCategory.Reason),
    accessType: UserAccessType.Engineer,
    id: UserSafeActivationReason.WeatherProjectionWind,
  },
  {
    title: "Panel Washing",
    icon: getIcons(ReasonIcons.Sprinklers, IconCategory.Reason),
    accessType: UserAccessType.Operator,
    id: UserSafeActivationReason.PanelWashing,
  },
  {
    title: "Tracker Maintenance",
    icon: getIcons(ReasonIcons.Tracker, IconCategory.Reason),
    accessType: UserAccessType.Engineer,
    id: UserSafeActivationReason.TrackerMaintenance,
  },
  {
    title: "User Maintenance",
    icon: getIcons(ReasonIcons.Maintenance, IconCategory.Reason),
    accessType: UserAccessType.Operator,
    id: UserSafeActivationReason.UserMaintenance,
  },
  {
    title: "Software Update",
    icon: getIcons(ReasonIcons.Software, IconCategory.Reason),
    accessType: UserAccessType.Engineer,
    id: UserSafeActivationReason.SoftwareUpdate,
  },
];
export const getEmergencyOptionListConfig = (userAccess: UserAccessType) => {
  return emergencyOptionListConfig.filter((item) => item.accessType <= userAccess);
};
