import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "./ScheduleCommand.module.css";
import SgTabs from "../../../SolarGikLib/tabs/SgTabs";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import SolarGikButton from "../../../SolarGikLib/Button";
import { filledBlueButton, outlinedWhiteButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import {
  addScheduleMaintenance,
  clearConflicts,
  fetchScheduledMaintenance,
  setScheduleOneTimeError,
  setScheduleRecurringError,
} from "./scheduleCommandStore";
import scheduleCommandTabs from "./ScheduleCommandTabs";
import {
  IAddScheduledMaintenanceDto,
  IScheduledMaintenanceOneTimeInput,
  IScheduledMaintenanceRecurringInput,
  ScheduledMaintenanceType,
} from "./Models";
import { TrackersCommandType } from "../TrackersModels";
import { AppDispatch, RootState } from "../../app/Store";
import { selectSiteId } from "../../sites/SiteStore";
import ConflictDialog from "./ConflictDialog";
import {
  getOneTimeScheduleValidationErrorOrNull,
  getRecurringScheduleValidationErrorOrNull,
} from "./validators";
import ScheduleCommandToast from "./ScheduleCommandToast";

interface IPropsScheduleCommand {
  onClose: () => void;
  isOpen: boolean;
  command: TrackersCommandType;
  reason: string;
  trackerIds: number[];
  elevationZenith: number;
}

const ScheduleCommand = ({
  onClose,
  isOpen,
  reason,
  trackerIds,
  elevationZenith,
}: Readonly<IPropsScheduleCommand>) => {
  const dispatch = useDispatch<AppDispatch>();
  const siteTimezone = useSelector((state: RootState) => state.site.ianaTimeZoneName);

  const { recurringScheduleData, oneTimeScheduleData } = useSelector(
    (state: RootState) => state.scheduleCommand
  );

  const siteId = useSelector(selectSiteId);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(ScheduledMaintenanceType.OneTime);
  const [toast, setToast] = useState({
    open: false,
    isSuccess: false,
    message: "",
  });

  const OnCloseConflicts = () => {
    dispatch(clearConflicts());
  };

  useEffect(() => {
    dispatch(fetchScheduledMaintenance(siteId));
  }, [siteId]);

  const onSave = async () => {
    if (!isScheduledDataValid()) {
      return;
    }

    setIsLoading(true);
    try {
      const scheduleData =
        activeTab === ScheduledMaintenanceType.OneTime
          ? mapOneTimeInputToScheduledMaintenance(oneTimeScheduleData)
          : mapRecurringInputToScheduledMaintenance(recurringScheduleData);

      await dispatch(
        addScheduleMaintenance({
          ianaTimeZone: siteTimezone,
          scheduledData: scheduleData,
        })
      ).unwrap();

      setToast({
        open: true,
        isSuccess: true,
        message: "Scheduled maintenance added",
      });
      onClose();
    } catch (error) {
      if (!isConflictError(error)) {
        setToast({
          open: true,
          isSuccess: false,
          message: "Failed to add scheduled maintenance. Please try again",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isScheduledDataValid = () => {
    if (activeTab === ScheduledMaintenanceType.OneTime) {
      const error = getOneTimeScheduleValidationErrorOrNull(oneTimeScheduleData);
      dispatch(setScheduleOneTimeError(error));
      return !error;
    } else {
      const error = getRecurringScheduleValidationErrorOrNull(recurringScheduleData);
      dispatch(setScheduleRecurringError(error));
      return !error;
    }
  };
  const isConflictError = (error: unknown): error is { conflicts: unknown } => {
    return Boolean(error && typeof error === "object" && "conflicts" in error);
  };
  const mapOneTimeInputToScheduledMaintenance = (
    oneTimeData: IScheduledMaintenanceOneTimeInput
  ): IAddScheduledMaintenanceDto => {
    return {
      siteId: siteId,
      trackerIds: trackerIds,
      targetElevationZenith: elevationZenith,
      startTimeUtc: oneTimeData.startDateTimeUtc,
      endTimeUtc: oneTimeData.endDateTimeUtc,
      reason: reason,
      recurrence: null,
    };
  };

  const mapRecurringInputToScheduledMaintenance = (
    recurringData: IScheduledMaintenanceRecurringInput
  ): IAddScheduledMaintenanceDto => {
    return {
      siteId: siteId,
      trackerIds: trackerIds,
      targetElevationZenith: elevationZenith,
      startTimeUtc: recurringData.fromDateUtc,
      endTimeUtc: recurringData.untilDateUtc,
      reason: reason,
      recurrence: {
        repeatEveryWeek: recurringData.repeatEveryWeek,
        daysInWeek: recurringData.selectedDays.reduce((acc, day: number) => acc | (1 << day), 0),
        startTime: recurringData.startTime,
        endTime: recurringData.endTime,
      },
    };
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogContent className={classes["dialog-content"]}>
          <span className={`${TextEnum.h4} ${classes["dialog-title"]}`}>Scheduler</span>
          <div className={classes["tabs-container"]}>
            <SgTabs
              tabs={scheduleCommandTabs}
              activeTab={activeTab}
              setActiveTab={(key) => setActiveTab(key as ScheduledMaintenanceType)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <SolarGikButton
            onClickFunc={onClose}
            text="Cancel"
            style={{
              ...outlinedWhiteButton,
              width: "110px",
              height: "47px",
            }}
            isDisabled={isLoading}
          />
          <div className={classes["save-container"]}>
            <SolarGikButton
              text={isLoading ? "" : "Save"}
              type="button"
              onClickFunc={onSave}
              isDisabled={isLoading || !oneTimeScheduleData || !recurringScheduleData}
              style={{
                ...filledBlueButton,
                width: "110px",
                height: "47px",
              }}
            />
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: 30,
                  left: 65,
                }}
              />
            )}
          </div>
        </DialogActions>
        <ConflictDialog onClose={OnCloseConflicts} />
      </Dialog>
      <ScheduleCommandToast
        open={toast.open}
        setOpen={(open: boolean) => setToast((prev) => ({ ...prev, open }))}
        isSuccess={toast.isSuccess}
        message={toast.message}
      />
    </>
  );
};

export default ScheduleCommand;
