import { PageNames } from "../../../pages/PageNames";
import { UserAccessType } from "../../user/Models";
import { SiteNavConfig } from "../NavigationDrawer/NavigationDrawerModels";
import {
  AgriIcon,
  ConfigurationIcon,
  DashboardIcon,
  HistoryIcon,
  IssuesIcon,
  SprinklersIcon,
  TrackersIcon,
  ActivityLogIcon,
  CamerasIcon,
} from "../../../SolarGikLib/icons/common/navigation/NavIcons";
import { FeatureFlag } from "../../featureFlags/FeatureFlagsModels";

export const NavItemsConfig: SiteNavConfig[] = [
  {
    pageName: "Story",
    pagePath: "/" + PageNames.StoryDashboard,
    icon: DashboardIcon,
    visiblityFilter: ({ userAccessType }) => userAccessType >= UserAccessType.Engineer,
  },
  {
    pageName: "Dashboard",
    pagePath: "/" + PageNames.Dashboard,
    icon: DashboardIcon,
    visiblityFilter: ({ userAccessType }) => userAccessType >= UserAccessType.ViewDashboard,
  },
  {
    pageName: "History",
    pagePath: "/" + PageNames.History,
    icon: HistoryIcon,
    visiblityFilter: ({ userAccessType }) => userAccessType >= UserAccessType.Viewer,
  },
  {
    pageName: "Trackers",
    pagePath: "/" + PageNames.Trackers,
    icon: TrackersIcon,
    visiblityFilter: ({ userAccessType }) => userAccessType >= UserAccessType.Viewer,
  },
  {
    pageName: "Activity Log",
    pagePath: "/" + PageNames.ActivityLog,
    icon: ActivityLogIcon,
    visiblityFilter: ({ userAccessType, siteMetadata }) =>
      userAccessType >= UserAccessType.Viewer && siteMetadata.visualInfo.isEnableUserRecordsFeature,
  },
  {
    pageName: "Agriculture",
    pagePath: "/" + PageNames.Agriculture,
    icon: AgriIcon,
    visiblityFilter: ({ userAccessType, siteMetadata }) =>
      userAccessType >= UserAccessType.Viewer && siteMetadata.visualInfo.isAgriPageVisible,
  },
  {
    pageName: "Alerts",
    pagePath: "/" + PageNames.Alerts,
    icon: IssuesIcon,
    visiblityFilter: ({ userAccessType, siteMetadata }) =>
      userAccessType >= UserAccessType.Viewer && siteMetadata.visualInfo.isEnableIssuesFeature,
  },
  {
    pageName: "Faucets",
    pagePath: "/" + PageNames.Faucets,
    icon: SprinklersIcon,
    visiblityFilter: ({ userAccessType, siteMetadata }) =>
      userAccessType >= UserAccessType.Viewer && siteMetadata.visualInfo.isEnableSprinklersFeature,
  },
  {
    pageName: "Config",
    pagePath: "/" + PageNames.Configuration,
    icon: ConfigurationIcon,
    visiblityFilter: ({ userAccessType }) => userAccessType >= UserAccessType.Engineer,
  },
  {
    pageName: "Cameras",
    pagePath: "/" + PageNames.Cameras,
    icon: CamerasIcon,
    visiblityFilter: ({ userAccessType, siteMetadata, siteFeatureFlags }) =>
      userAccessType >= UserAccessType.Viewer &&
      siteFeatureFlags.some((flag) => flag === FeatureFlag.CamerasPage) &&
      siteMetadata.cameras.length > 0,
  },
];
