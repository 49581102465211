import React, { useState, useRef, useEffect } from "react";
import { TextField } from "@mui/material";
import SolarGikButton from "../../../SolarGikLib/Button";
import { muteDaysButtonStyle, muteDaysInputStyle } from "./MuteDaysStyle";
import classes from "./MuteAlertDaysInput.module.css";

interface IMuteAlertDaysInputProps {
  onSend: (days: number) => void;
}

const MuteAlertDaysInput: React.FC<IMuteAlertDaysInputProps> = ({ onSend }) => {
  const [days, setDays] = useState<number>();
  const [error, setError] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue === "") {
      setDays(undefined);
      return;
    }
    const value = Number(inputValue);
    if (!Number.isInteger(value)) {
      return;
    }

    let errorMessage = "";
    if (value < 1 || value > 15) {
      errorMessage = "15 days maximum";
    }
    setDays(value);
    setError(errorMessage);
  };

  return (
    <div className={classes["container"]}>
      <div className={classes["input-wrapper"]}>
        <div className={classes["input-container"]}>
          <TextField
            placeholder="Type a number"
            type="text"
            value={days ?? ""}
            onChange={handleDaysChange}
            size="small"
            error={error !== ""}
            sx={muteDaysInputStyle}
            helperText={error}
            autoFocus
            inputRef={inputRef}
            InputProps={{
              endAdornment: <span className={classes["end-adornment"]}>Days</span>,
            }}
          />
        </div>
        <SolarGikButton
          style={muteDaysButtonStyle}
          text="Send"
          isDisabled={error !== "" || !days}
          onClickFunc={() => {
            if (days) {
              onSend(days);
            }
          }}
        />
      </div>
    </div>
  );
};

export default MuteAlertDaysInput;
