import dayjs from "dayjs";
import { IScheduledMaintenanceOneTimeInput, IScheduledMaintenanceRecurringInput } from "./Models";

export const getOneTimeScheduleValidationErrorOrNull = (
  data: IScheduledMaintenanceOneTimeInput
): string | null => {
  if (!data.startDateTimeUtc || !data.endDateTimeUtc) {
    return "Please select both start and end times.";
  }

  const start = dayjs(data.startDateTimeUtc);
  const now = dayjs();
  const end = dayjs(data.endDateTimeUtc);
  const oneYearFromNow = dayjs().add(1, "year");

  if (start.isBefore(now)) {
    return "Start time cannot be in the past.";
  }

  if (start.isAfter(end)) {
    return "End time must be after start time.";
  }

  if (end.isAfter(oneYearFromNow)) {
    return "Schedule must be within 1 year from now.";
  }
  return null;
};

const hasSelectedDayInDateRange = (
  recurringScheduleData: IScheduledMaintenanceRecurringInput
): boolean => {
  const { selectedDays, fromDateUtc, untilDateUtc } = recurringScheduleData;
  const startDate = dayjs(fromDateUtc);
  const endDate = dayjs(untilDateUtc);

  for (let date = startDate; date.isBefore(endDate) || date.isSame(endDate); date = date.add(1, "day")) {
    if (selectedDays.includes(date.day())) {
      return true;
    }
  }
  return false;
};

export const getRecurringScheduleValidationErrorOrNull = (
  data: IScheduledMaintenanceRecurringInput
): string | null => {
  const { repeatEveryWeek, selectedDays, startTime, endTime, fromDateUtc, untilDateUtc } = data;
  if (dayjs(fromDateUtc).isAfter(dayjs(untilDateUtc))) {
    return "End date must be after start date.";
  }

  if (!fromDateUtc) {
    return "Please select a start date.";
  }
  if (!untilDateUtc) {
    return "Please select an end date.";
  }
  if (!repeatEveryWeek) {
    return "Please select repeat frequency.";
  }
  if (!selectedDays || selectedDays.length === 0) {
    return "Please select at least one day.";
  }
  if (!startTime) {
    return "Please select a start time.";
  }
  if (!endTime) {
    return "Please select an end time.";
  }

  const oneYearAhead = dayjs().add(1, "year");
  if (dayjs(fromDateUtc).isAfter(oneYearAhead) || dayjs(untilDateUtc).isAfter(oneYearAhead)) {
    return "End time must be within 1 year from now.";
  }

  if (!hasSelectedDayInDateRange(data)) {
    return "Please make sure at least one day is in the scheduled range.";
  }

  const relayHour = dayjs(endTime, "HH:mm");
  const fromDateUtcWithRelayHour = dayjs(fromDateUtc)
    .add(relayHour.hour(), "hour")
    .add(relayHour.minute(), "minute");
  const now = dayjs();
  if (fromDateUtcWithRelayHour.isBefore(now)) {
    return "Cannot schedule maintenance that starts in the past.";
  }
  return null;
};
