import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Divider, FormControlLabel, FormGroup, Skeleton, Switch } from "@mui/material";
import classes from "./SiteFeaturesEditor.module.css";
import { AppDispatch, RootState } from "../../app/Store";
import { selectSiteId } from "../../sites/SiteStore";
import { FeatureFlag } from "../../featureFlags/FeatureFlagsModels";
import * as featureFlagApi from "../../featureFlags/FeatureFlagsApi";
import * as externalFeaturesSettingsApi from "../../external_features_settings/ExternalFeaturesSettingsApi";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import { fetchFeatureFlags } from "../../featureFlags/SitesFeatureFlagsStore";
import { humanizeEnumValue } from "../../../common/EnumUtils";
import { IExternalFeaturesSettingsDto } from "../../external_features_settings/ExternalFeaturesSettingsModels";
import ForecastSafetySettingsEditor from "./ForecastSafetySettingsEditor";

const SiteFeaturesEditor = () => {
  const siteId = useSelector(selectSiteId);
  const featureFlagEnabledInStore = useSelector(
    (state: RootState) => state.featureFlags.sitesFeatureFlags[siteId]
  );
  const initialExternalFeaturesSettingState: IExternalFeaturesSettingsDto = {
    forecastSafetySettings: { isEnabled: false, threshold: 16 },
  };
  const dispatch = useDispatch<AppDispatch>();
  const userData = useSelector((state: RootState) => state.user);
  const siteIds = userData.siteIds;

  const [isLoading, setIsLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();

  const [enabledFeatureFlags, setEnabledFeatureFlags] = useState<FeatureFlag[]>([]);
  const [externalFeaturesSettings, setExternalFeaturesSettings] = useState<IExternalFeaturesSettingsDto>(
    initialExternalFeaturesSettingState
  );

  const fetchExternalFeaturesSettings = async (siteId: string) => {
    const externalfeaturesSettings =
      await externalFeaturesSettingsApi.getExternalFeaturesSettingsAsync(siteId);
    setExternalFeaturesSettings(externalfeaturesSettings);
    setIsLoading(false);
  };

  useEffect(() => {
    setEnabledFeatureFlags(featureFlagEnabledInStore);
    fetchExternalFeaturesSettings(siteId);
  }, [siteId, featureFlagEnabledInStore]);

  const allFeatureFlags = Object.values(FeatureFlag);

  const toggleFeatureFlag = (flag: FeatureFlag) => {
    setEnabledFeatureFlags(
      enabledFeatureFlags.includes(flag)
        ? enabledFeatureFlags.filter((f) => f !== flag)
        : enabledFeatureFlags.concat(flag)
    );
  };

  const toggleForecastSafetyFeature = () => {
    setExternalFeaturesSettings((prev) => ({
      ...prev,
      forecastSafetySettings: {
        ...prev.forecastSafetySettings,
        isEnabled: !prev.forecastSafetySettings.isEnabled,
      },
    }));
  };

  const updateForecastSafetyThreshold = (newThreshold: number) => {
    setExternalFeaturesSettings((prev) => ({
      ...prev,
      forecastSafetySettings: { ...prev.forecastSafetySettings, threshold: newThreshold },
    }));
  };

  const saveChanges = async () => {
    const ffMap = allFeatureFlags.reduce(
      (accumulator, flag) => {
        accumulator[flag] = enabledFeatureFlags.includes(flag);
        return accumulator;
      },
      {} as { [flag: string]: boolean }
    );
    setIsLoading(true);
    try {
      await featureFlagApi.updateSiteFeatureFlagsAsync(siteId, ffMap);
      await externalFeaturesSettingsApi.updateExternalFeaturesSettingsAsync(siteId, {
        ...externalFeaturesSettings,
      });
      setAlertMessage({
        text: "Changes saved successfully",
        severity: "success",
      });
    } catch (error) {
      setAlertMessage({
        text: "Failed to save changes",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
    await dispatch(fetchFeatureFlags(siteIds));
    await fetchExternalFeaturesSettings(siteId);
  };

  return (
    <div className={classes["container"]}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      {isLoading && <Skeleton variant="rounded" width="100%" height="300px" />}
      {!isLoading && (
        <>
          <FormGroup>
            <div className={classes["form-container"]}>
              <div className={classes["soma-portal-features"]}>
                {allFeatureFlags.map((flag) => (
                  <FormControlLabel
                    key={flag}
                    control={
                      <Switch
                        checked={enabledFeatureFlags.includes(flag)}
                        onChange={() => toggleFeatureFlag(flag)}
                      />
                    }
                    label={formatFeatureFlagName(flag)}
                  />
                ))}
              </div>

              <Divider orientation="vertical" flexItem />

              <Box className={classes["external-services-features"]}>
                <ForecastSafetySettingsEditor
                  forecastSafetyEnabled={
                    externalFeaturesSettings?.forecastSafetySettings?.isEnabled as boolean
                  }
                  toggleForecastSafety={toggleForecastSafetyFeature}
                  forecastThreshold={
                    externalFeaturesSettings?.forecastSafetySettings?.threshold as number
                  }
                  updateForecastSafetyThreshold={updateForecastSafetyThreshold}
                />
              </Box>
            </div>
            <div>
              <Button className={classes["form-button"]} variant="contained" onClick={saveChanges}>
                Save Changes
              </Button>
            </div>
          </FormGroup>
          <br />
        </>
      )}
    </div>
  );
};

export default SiteFeaturesEditor;

function formatFeatureFlagName(flag: FeatureFlag): string {
  const text = humanizeEnumValue(flag) ?? flag;
  return text[0] + text.slice(1).toLowerCase();
}
