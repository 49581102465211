import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { repetitivePolling } from "../common/AsyncUtils";
import APP_CONFIG from "../features/app/configuration/AppConfig";
import { AppDispatch, RootState } from "../features/app/Store";
import { fetchTrackerStatus, onFieldStateDisconnected } from "../features/app/store/TrackersStore";
import { TrackersStateEnum } from "../features/trackers/TrackersModels";
import { PixelsView } from "../features/trackers/pixelsView/PixelsView";
import { FeatureFlag } from "../features/featureFlags/FeatureFlagsModels";
import { ITab } from "../SolarGikLib/tabs/TabsModels";
import SgTabs from "../SolarGikLib/tabs/SgTabs";
import { TrackersTableView } from "../features/trackers/TrackersTableView";
import { is150PercentScreen } from "../common/WindowUtils";
import { selectFieldState } from "../features/app/store/MultisiteTagsStore";
import { FieldState } from "../features/data_point/models/TagsModels";
import { selectTrackerIds } from "../features/sites/SiteStore";
import classes from "./Trackers.module.css";
import {
  setOneTimeScheduleData,
  setRecurringScheduleData,
} from "../features/trackers/ScheduleCommand/scheduleCommandStore";

export enum TrackerViewType {
  TABLE = "tableView",
  MAP = "mapView",
}

const Trackers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const siteMetadata = useSelector((state: RootState) => state.site);
  const featureFlags = useSelector(
    (state: RootState) => state.featureFlags.sitesFeatureFlags[siteMetadata.siteId]
  );
  const trackerIds = useSelector(selectTrackerIds);
  const isLoading = useSelector((state: RootState) => state.multiSitesTrackers.loading);
  const fieldState = useSelector((state: RootState) => selectFieldState(state, siteMetadata.siteId));
  const trackersStatuses = useSelector(
    (state: RootState) => state.multiSitesTrackers.trackersStatus[siteMetadata.siteId]
  );
  const isFieldStateDisconnected = fieldState.value === FieldState.Disconnected;

  const [activeTab, setActiveTab] = useState<TrackerViewType>(TrackerViewType.TABLE);
  const recurringScheduleData = useSelector(
    (state: RootState) => state.scheduleCommand.recurringScheduleData
  );

  const now = new Date().getTime() + 60 * 1000;
  const tenMinutesFromNow = now + 10 * 60 * 1000;

  useEffect(() => {
    dispatch(
      setOneTimeScheduleData({
        startDateTimeUtc: now,
        endDateTimeUtc: tenMinutesFromNow,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      setRecurringScheduleData({
        ...recurringScheduleData,
        fromDateUtc: now,
        untilDateUtc: tenMinutesFromNow,
      })
    );
  }, []);

  useEffect(() => {
    const cntrlr = new AbortController();
    if (isFieldStateDisconnected) {
      dispatch(
        onFieldStateDisconnected({
          siteId: siteMetadata.siteId,
          trackerIds,
          trackerNames: siteMetadata.trackers,
        })
      );
    } else {
      repetitivePolling(
        "pollingTrackers",
        pollingTrackers,
        APP_CONFIG.milliSecBetweenGetTrackers,
        APP_CONFIG.milliSecBetweenGetTrackers,
        cntrlr.signal
      );
    }
    return () => {
      cntrlr.abort();
    };
  }, [siteMetadata, isFieldStateDisconnected]);

  useEffect(() => {
    if (!isFieldStateDisconnected) {
      return;
    }
    const hasAvailableTrackers = trackersStatuses?.some(
      (tracker) => tracker.currentState !== TrackersStateEnum.NotAvailable
    );
    if (hasAvailableTrackers) {
      dispatch(
        onFieldStateDisconnected({
          siteId: siteMetadata.siteId,
          trackerIds,
          trackerNames: siteMetadata.trackers,
        })
      );
    }
  }, [trackersStatuses, isFieldStateDisconnected]);

  const pollingTrackers = async () => {
    dispatch(fetchTrackerStatus(siteMetadata.siteId));
  };

  const trackersViewTabs: ITab[] = [
    {
      label: "Table View",
      key: TrackerViewType.TABLE,
      content: <TrackersTableView isLoading={isLoading} />,
    },
    {
      label: "Map View",
      key: TrackerViewType.MAP,
      content: null,
    },
  ];

  const is150Percent = is150PercentScreen();
  const isMapView = activeTab === TrackerViewType.MAP;
  return featureFlags.includes(FeatureFlag.TrackersPixelsView) ? (
    <div style={{ position: "relative", height: "100%" }}>
      <SgTabs
        tabs={trackersViewTabs}
        tabPanelStyle={{ height: is150Percent ? "80%" : "90%" }}
        setActiveTab={(key: string) => setActiveTab(key as TrackerViewType)}
        activeTab={activeTab}
      />
      <div className={`${classes["pixels-container"]} ${isMapView ? classes["visible"] : ""}`}>
        <PixelsView siteId={siteMetadata.siteId} />
      </div>
    </div>
  ) : (
    <TrackersTableView isLoading={isLoading} />
  );
};

export default Trackers;
