import { FC } from "react";
import classes from "./BatteryStatus.module.css";
import { ChargingStateEnum } from "./TrackersModels";
import TrackerBatteryIcon from "./TrackerBatteryIcon";
import ChargingBatteryIcon from "./ChargingBatteryIcon";

interface IBatteryStatusProps {
  chargePercentage?: number;
  chargingState?: number;
  formattedChargePercentage: string;
  formattedChargingState: string;
}
const BatteryStatus: FC<IBatteryStatusProps> = ({ chargePercentage, chargingState, formattedChargePercentage, formattedChargingState }) => {
  const isCharging = chargingState === ChargingStateEnum.Charging;
  return (
    <div className={classes.row}>
      <div className={classes["charging-state"]}>
        {formattedChargingState}
      </div>
      <div className={classes["right-border"]}></div>
      <div className={classes["charging-percent"]}>
        <div className={classes["battery-icon"]}>
          {!isCharging && <TrackerBatteryIcon chargePercentage={chargePercentage} />}
          {isCharging && <ChargingBatteryIcon chargePercentage={chargePercentage} />}
        </div>
        <div className={classes["charging-percent-text"]}>
          {formattedChargePercentage}
        </div>
      </div>
    </div>
  );
};
export default BatteryStatus;
