import { FunctionComponent, SVGProps } from "react";

import { IListItem } from "../../../SolarGikLib/Lists/ListModels";
import { UserAccessType } from "../../user/Models";

export interface IEmergencyStatus {
  DesiredState: boolean;
  ReportedState: boolean;
}
export enum UserSafeModeState {
  UserSafeModeOff = "Safe",
  UserSafeModeRequested = "Pending",
  UserSafeModeOn = "In Safe",
}

export interface IEmergencyListitem extends IListItem {
  title: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  accessType: UserAccessType;
}

export enum UserSafeActivationReason {
  WeatherProjectionWind = 1,
  WeatherProjectionSnow = 2,
  PanelWashing = 3,
  TrackerMaintenance = 4,
  UserMaintenance = 5,
  SoftwareUpdate = 6,
}

export enum SafetyReason {
  Unknown = -1,
  NotSafe = 0,
  WindSafe = 1 << 0,
  UserSafe = 1 << 1,
  EngineerSafe = 1 << 2,
  UpsSafe = 1 << 3,
  SnowSafe = 1 << 4,
}

export enum EmergencyCommand {
  RequestUserSafeMode = "User Safe Mode Request",
  ReleaseUserSafeMode = "User Safe Mode Release Request",
}

export interface IEmergencyToastState {
  open: boolean;
  isSuccessful: boolean;
  command: EmergencyCommand;
}
