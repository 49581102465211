import { Dispatch, SetStateAction } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { inputStyle } from "../../../SolarGikLib/fields/InputStyle";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import { IUserDetails } from "../../user/Models";
import { IAlertsSubscriberWithUserDetails } from "./AlertsSubscribersGrid";

interface IUserDetailsEditCellProps {
    params: GridRenderEditCellParams;
    rows: IAlertsSubscriberWithUserDetails[];
    usersDetails: IUserDetails[];
    setSitesIds: Dispatch<SetStateAction<string[]>>;
}

const UserDetailsEditCell = ({ params, rows, usersDetails, setSitesIds }: IUserDetailsEditCellProps) => {
    const { id, value, field } = params;
    const apiRef = useGridApiContext();
    const handleValueChange = (newValue: IUserDetails | null) => {
        if (!newValue) {
            return;
        }
        setSitesIds(newValue.siteIds);
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    return <Autocomplete
        size="small"
        classes={{ input: TextEnum.h5, listbox: TextEnum.h5 }}
        sx={{ width: "100%" }}
        getOptionLabel={(option) => option.email}
        options={usersDetails.filter((option) => !rows.map(row => row.userDetails.userId).includes(option.userId))}
        isOptionEqualToValue={(option, value) => option.userId === value?.userId}
        renderInput={(params) => <TextField {...params} sx={inputStyle} value={value?.email ?? ""} />}
        onChange={(_event, newValue) => handleValueChange(newValue)}
        value={usersDetails.find(user => user.userId === value?.userId)}
        key={"email"}
        id={"email"}
    />
}

export default UserDetailsEditCell;