import { useDispatch } from "react-redux";
import classes from "./StoryDashboard.module.css";
import StoryCard from "../SolarGikLib/cards/StoryCard";
import { PageNames } from "./PageNames";
import { FaultsTabOptions, setActiveTab } from "../features/app/store/FaultsPageStore";

const StoryDashboard = () => {
  const dispatch = useDispatch();

  return (
    <div className={classes["background-window"]}>
      <div className={classes["grid-container"]}>
        <div className={classes["current-production"]}>
          <StoryCard title={"Current Production"}></StoryCard>
        </div>
        <div className={classes["wind"]}>
          <StoryCard title={"Wind"}></StoryCard>
        </div>
        <div className={classes["commulative-production"]}>
          <StoryCard title={"Cummulative Production"}></StoryCard>
        </div>
        <div className={classes["top-issues"]}>
          <StoryCard
            title={"Top Issues"}
            navigationLink={{
              text: "All Issues",
              targetPage: PageNames.Alerts,
              beforeNavigate: () => dispatch(setActiveTab(FaultsTabOptions.Issues)),
            }}
          ></StoryCard>
        </div>
        <div className={classes["production-irradiance"]}>
          <StoryCard title={"Production vs. Irradiance"}></StoryCard>
        </div>
        <div className={classes["inverter-performance"]}>
          <StoryCard title={"Inverter Performance"}></StoryCard>
        </div>
        <div className={classes["tracker-states"]}>
          <StoryCard
            title={"Tracker States"}
            navigationLink={{ text: "View Trackers", targetPage: PageNames.Trackers }}
          ></StoryCard>
        </div>
      </div>
    </div>
  );
};
export default StoryDashboard;
