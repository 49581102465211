import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/Store";
import { fetchAlertsSubscribers } from "../../app/store/AlertsSubscribersStore";
import AlertsSubscribersGrid from "./AlertsSubscribersGrid";

import { IUserDetails } from "../../user/Models";
import { getAllUsersDetails } from "../../user/UserAPI";

const AlertsSubscribers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [usersDetails, setUsersDetails] = useState<IUserDetails[]>([]);

  const fetchUsersDetails = async (signal: AbortSignal) => {
    try {
      const response = await getAllUsersDetails(signal);
      if (response) {
        setUsersDetails(response);
      }
    } catch (error) {
      if (!signal.aborted) {
        console.error("Failed to fetch user details:", error);
      }
    }
  };

  useEffect(() => {
    dispatch(fetchAlertsSubscribers());
    const controller = new AbortController();
    fetchUsersDetails(controller.signal);
    return () => {
      controller.abort();
    };
  }, []);

  const subscribers = useSelector((state: RootState) => state.faults.subscribers.subscribers) ?? [];

  return (
    <>
      <h1>Alert Notification Subscribers</h1>
      <AlertsSubscribersGrid subscribers={subscribers} usersDetails={usersDetails} />
    </>
  );
};
export default AlertsSubscribers;
