import { getGridStringOperators, GridColDef, GridComparatorFn, GridSortCellParams } from "@mui/x-data-grid";
import BatteryStatus from "./BatteryStatus";
import { convertNumberToTimeSpan } from "../../common/StringUtils";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import CurrentState from "./CurrentState";
import TrackerIssues from "./TrackerIssues";
import { ChargingStateEnum, TrackersStateEnum } from "./TrackersModels";
import { humanizeEnumValue } from "../../common/EnumUtils";

const chargePercentageComparator: GridComparatorFn = (_v1, _v2, params1, params2) => {
  const getRawPercentage = (params: GridSortCellParams) =>
    params.api.getRow(params.id).batteryStatus.chargePercentage
  const raw1 = getRawPercentage(params1);
  const raw2 = getRawPercentage(params2);
  if (raw1 == null) {
    return -1;
  }
  if (raw2 == null) {
    return 1;
  }
  return raw1 - raw2;
}

const getFormattedChargingState = (chargingState: number): string =>
  chargingState == undefined
    ? NO_VALUE_PLACEHOLDER
    : (humanizeEnumValue(ChargingStateEnum[chargingState]) ?? NO_VALUE_PLACEHOLDER)

const getFormattedChargePercentage = (chargePercentage: number): string =>
  chargePercentage == null ? NO_VALUE_PLACEHOLDER : `${chargePercentage}%`

export const trackersTableColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1.4,
    valueGetter: (params) => params.value.name ?? NO_VALUE_PLACEHOLDER,
    renderCell: (params) => (
      <TrackerIssues trackerName={params.row.name.name} activeIssues={params.row.name.activeIssues} />
    ),
  },
  {
    field: "id",
    headerName: "ID",
    flex: 0.3,
    valueGetter: (params) => params.value ?? NO_VALUE_PLACEHOLDER,
  },
  {
    field: "currentState",
    headerName: "State",
    flex: 0.8,
    renderCell: (params) => (
      <CurrentState
        currentState={params.row.currentState.current}
        targetState={params.row.currentState.target}
      />
    ),
    valueGetter: (params) =>
      params.value.current == TrackersStateEnum.InProgress
        ? (humanizeEnumValue(TrackersStateEnum[params.value.target]) ?? NO_VALUE_PLACEHOLDER)
        : (humanizeEnumValue(TrackersStateEnum[params.value.current]) ?? NO_VALUE_PLACEHOLDER),
  },
  {
    field: "currentElevation",
    headerName: "Elevation",
    flex: 0.6,
    valueGetter: (params) => params.value ?? NO_VALUE_PLACEHOLDER,
  },
  {
    field: "shadingRatio",
    headerName: "Shading Ratio",
    flex: 0.7,
    valueGetter: (params) => params.value ?? NO_VALUE_PLACEHOLDER,
  },
  {
    field: "batteryStatus",
    headerName: "Battery Status",
    flex: 1.2,
    renderCell: (params) => (
      <BatteryStatus
        chargePercentage={params.row.batteryStatus.chargePercentage}
        chargingState={params.row.batteryStatus.chargingState}
        formattedChargePercentage={getFormattedChargePercentage(params.row.batteryStatus.chargePercentage)}
        formattedChargingState={getFormattedChargingState(params.row.batteryStatus.chargingState)}
      />
    ),
    valueGetter: (params) => {
      return `${getFormattedChargingState(params.value.chargingState)} ${getFormattedChargePercentage(params.value.chargePercentage)}`
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value !== "equals" && operator.value !== "isAnyOf"
    ),
    sortComparator: chargePercentageComparator
  },
  {
    field: "uptime",
    headerName: "Uptime",
    flex: 0.7,
    valueGetter: (params) =>
      params.value != null ? convertNumberToTimeSpan(params.value) : NO_VALUE_PLACEHOLDER,
  },
];
