export const toastStyle = {
  "& .MuiPaper-root": {
    width: "400px",
    backgroundColor: "#595959",
    borderRadius: "30px",
    justifyContent: "center",
    letterSpacing: "0.05em",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "23px",
    textAlign: "center",
    whiteSpace: "pre-line",
  },
  "& .MuiSnackbarContent-action ": {
    margin: "0px",
    borderRadius: "30px 0px 0px 0px",
  },
};

export const toastButtonStyle = {
  backgroundColor: "white",
  width: "70px",
  height: "30px",
  color: "#828282",
  borderRadius: "8px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
};
