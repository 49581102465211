import { Dispatch, SetStateAction, FC } from "react";
import { GridRowsProp, GridRowModesModel, GridRowModes } from "@mui/x-data-grid";
import SolarGikButton from "../../../SolarGikLib/Button";
import { narrowBlueButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import { IAlertsSubscriberWithUserDetails, TemporaryUserId } from "./AlertsSubscribersGrid";
import classes from "./EditToolbar.module.css";

const defaultAlertsSubscriber: IAlertsSubscriberWithUserDetails = {
    userDetails: { userId: "", email: "", siteIds: ["*"] },
    siteIds: [],
    alertIds: [],
};

interface IEditToolbarProps {
    setRows: Dispatch<SetStateAction<GridRowsProp>>;
    setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
    setIsAddingNewRow: Dispatch<SetStateAction<boolean>>
}

const EditToolbar: FC<IEditToolbarProps> = ({ setRows, setRowModesModel, setIsAddingNewRow }) => {
    const handleClick = () => {
        const id = TemporaryUserId;
        setRows((oldRows) => [...oldRows, { ...defaultAlertsSubscriber, userDetails: { ...defaultAlertsSubscriber.userDetails, userId: id } }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: "userDetails" },
        }));
        setIsAddingNewRow(true);
    };

    return (
        <div className={classes["toolbar"]}>
            <SolarGikButton
                style={{ ...narrowBlueButton, width: "180px" }}
                onClickFunc={handleClick}
                text="Add Subscriber"
            />
        </div>
    );
};

export default EditToolbar;