import axios from "axios";

import APP_CONFIG from "../app/configuration/AppConfig";
import { IUserDataDto, IUserDetails } from "./Models";

export async function getUserData(signal: AbortSignal): Promise<IUserDataDto> {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/auth-user/info`;
  const httpResponse = await axios.get<IUserDataDto>(url, { signal });
  return httpResponse.data;
}

export async function getAllUsersDetails(signal: AbortSignal): Promise<IUserDetails[]> {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/users/details`;
  const httpResponse = await axios.get<IUserDetails[]>(url, {
    signal,
  });
  return httpResponse.data;
}