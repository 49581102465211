import axios from "axios";

import {
  AlertTransition,
  IAlertsHistory,
  IAlertsSubscriber,
  IFaultsMetadata,
  IMuteAlert,
  ISingleDeviceIssue,
  ISiteActiveAlerts,
  ISitesIssueCounters,
} from "./DTOs";
import { IMutedAlert, ISubscribedAlerts } from "./Models";
import APP_CONFIG from "../app/configuration/AppConfig";

export const getAlertsHistory = async (
  siteId: string,
  from: Date,
  to: Date,
  signal: AbortSignal
): Promise<IAlertsHistory> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/${siteId}/alerts/history`;
  const httpResponse = await axios.get<IAlertsHistory>(url, {
    signal,
    params: { from, to },
  });
  return httpResponse.data;
};

export const getActiveIssues = async (
  siteId: string,
  signal: AbortSignal
): Promise<ISingleDeviceIssue[]> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/${siteId}/issues/active`;
  const httpResponse = await axios.get<ISingleDeviceIssue[]>(url, { signal });
  return httpResponse.data;
};

export const getActiveAlerts = async (
  siteId: string,
  signal: AbortSignal
): Promise<ISiteActiveAlerts> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/${siteId}/alerts/active`;
  const httpResponse = await axios.get<ISiteActiveAlerts>(url, { signal });
  return httpResponse.data;
};

export const getAlertTransitions = async (
  siteId: string,
  alertKind: "single-device" | "aggregated",
  alertId: number,
  signal: AbortSignal
): Promise<AlertTransition[]> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/${siteId}/alerts/${alertKind}/${alertId}/history`;
  const httpResponse = await axios.get<AlertTransition[]>(url, { signal });
  return httpResponse.data;
};

export const getSitesActiveIssuesCounters = async (
  signal: AbortSignal
): Promise<ISitesIssueCounters> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/issues/active/counters`;
  const httpResponse = await axios.get<ISitesIssueCounters>(url, { signal });
  return httpResponse.data;
};

export async function getFaultsMetadata(): Promise<IFaultsMetadata> {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/metadata`;
  const httpResponse = await axios.get<IFaultsMetadata>(url);
  return httpResponse.data;
}

export async function getAlertsSubscribers(signal: AbortSignal): Promise<IAlertsSubscriber[]> {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/subscribers`;
  const httpResponse = await axios.get<IAlertsSubscriber[]>(url, {
    signal,
  });
  return httpResponse.data;
}

export async function upsertAlertsSubscriber(
  subscriber: IAlertsSubscriber,
  signal: AbortSignal
): Promise<void> {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/subscribers`;
  await axios.post<IAlertsSubscriber>(url, subscriber, {
    signal,
  });
}

export async function deleteAlertsSubscriber(userId: string, signal: AbortSignal): Promise<void> {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/subscribers/${userId}`;
  await axios.delete<void>(url, { signal });
}

export const getMutedAlerts = async (siteId: string, signal: AbortSignal): Promise<IMutedAlert[]> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/${siteId}/alerts/mute`;
  const response = await axios.get<IMutedAlert[]>(url, { signal });
  return response.data;
};

export const muteAlert = async (
  muteAlertParams: IMuteAlert,
  signal: AbortSignal
): Promise<IMutedAlert> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/${muteAlertParams.siteId}/alerts/mute`;
  const response = await axios.post<IMutedAlert>(url, muteAlertParams, { signal });
  return response.data;
};

export const unmuteAlert = async (
  muteId: number,
  siteId: string,
  signal: AbortSignal
): Promise<void> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/${siteId}/alerts/mute/${muteId}`;
  await axios.delete(url, { signal });
};

export const getUserSubscribedAlerts = async (
  siteId: string,
  signal: AbortSignal
): Promise<ISubscribedAlerts> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/${siteId}/subscribers/subscribed-alerts`;
  const response = await axios.get<ISubscribedAlerts>(url, { signal });
  return response.data;
};