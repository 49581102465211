export enum PageNames {
  StoryDashboard = "StoryDashboard",
  Dashboard = "Dashboard",
  Trackers = "Trackers",
  History = "History",
  Alerts = "Alerts",
  Agriculture = "Agriculture",
  Configuration = "Configuration",
  Faucets = "Faucets",
  ActivityLog = "ActivityLog",
  Trends = "Trends",
  CloudConfiguration = "CloudConfiguration",
  MultisiteTable = "MultisiteTable",
  Cameras = "Cameras",
  Subscribers = "FaultSubscribers",
}
