import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { AppDispatch, RootState } from "../../../app/Store";
import { muteAlertAction, unmuteAlertAction } from "../../../app/store/FaultsStore";
import { MuteStatus } from "../../Models";
import LightTooltip from "../../../../SolarGikLib/tooltip/LightTooltip";
import getIcons from "../../../../SolarGikLib/icons/Icons";
import { IconCategory, SomaIcons } from "../../../../SolarGikLib/icons/IconsModels";
import { selectSiteDateTimeFormatter } from "../../../sites/SiteStore";
import classes from "./MuteAlertCell.module.css";
import MuteAlertToast, { ToastAction, MuteAlertToastActionType } from "../MuteAlertToast";
import SgPopover from "../../../../SolarGikLib/popover/SgPopover";
import { muteDaysPopoverStyle, muteDaysArrowStyle } from "../MuteDaysStyle";
import MuteAlertDaysInput from "../MuteAlertDaysInput";

interface IMuteAlertCellProps {
  alertType: number;
  siteId: string;
  value: MuteStatus;
}

const customTooltipSx = {
  fontSize: 14,
  fontWeight: 400,
  whiteSpace: "pre-line",
};

const MutedIcon = getIcons(SomaIcons.AlertMuted, IconCategory.Soma);
const UnmutedIcon = getIcons(SomaIcons.AlertUnmuted, IconCategory.Soma);

const TooltipTexts = {
  unsubscribed: "Notifications are not sent",
  muted: (date: string) => `Muted until ${date}.\n Click to unmute.`,
  unmuted: "Click to mute",
} as const;

const MuteAlertCell: FC<IMuteAlertCellProps> = ({ alertType, siteId, value }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showToast, setShowToast] = useState(false);
  const [toastAction, setToastAction] = useState<ToastAction>();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const dtFormatter = useSelector(selectSiteDateTimeFormatter);
  const mutedAlert = useSelector(
    (state: RootState) => state.faults.multiSitesFaults.mutedAlerts[siteId]?.data?.[alertType]
  );

  const handleUnmute = async () => {
    if (value !== MuteStatus.Muted || !mutedAlert) {
      return;
    }

    try {
      dispatch(unmuteAlertAction({ siteId, muteId: mutedAlert.id }));
      setToastAction({ type: MuteAlertToastActionType.Unmute });
      setShowToast(true);
    } catch (err) {
      setToastAction({
        type: MuteAlertToastActionType.Error,
        errorMessage: "Failed to unmute alert",
      });
      setShowToast(true);
    }
  };

  const getTooltipText = () => {
    if (value === MuteStatus.Unsubscribed) {
      return TooltipTexts.unsubscribed;
    }
    if (value === MuteStatus.Muted && mutedAlert) {
      const expirationDate = dtFormatter.formatDateAndTime(new Date(mutedAlert.expiresAtUTC));
      return TooltipTexts.muted(expirationDate);
    }
    return TooltipTexts.unmuted;
  };

  const getIcon = () => {
    if (value === MuteStatus.Unsubscribed) {
      return <UnmutedIcon className={classes["disabled"]} />;
    }
    if (value === MuteStatus.Muted) {
      return <MutedIcon />;
    }
    return <UnmutedIcon />;
  };
  const Icon = getIcon();

  const handleMuteClick = () => {
    if (value === MuteStatus.Unsubscribed) {
      return;
    }

    if (value === MuteStatus.Muted) {
      handleUnmute();
      return;
    }
    setIsPopoverOpen(true);
  };

  const handleClose = () => {
    setIsPopoverOpen(false);
  };

  const handleSendMuteAlert = async (days: number) => {
    if (days < 1 || days > 15) {
      return;
    }
    try {
      const muteAlertResult = await dispatch(
        muteAlertAction({ siteId, alertType, expirationDays: days })
      ).unwrap();

      setToastAction({
        type: MuteAlertToastActionType.Mute,
        expirationDate: muteAlertResult.mutedAlert.expiresAtUTC,
      });
      setShowToast(true);
    } catch (err) {
      console.error("Mute alert error:", err);
      setToastAction({
        type: MuteAlertToastActionType.Error,
        errorMessage: "Failed to mute alert",
      });
      setShowToast(true);
    } finally {
      setIsPopoverOpen(false);
    }
  };

  return (
    <>
      <SgPopover
        TriggerButton={
          <span className={classes["icon-container"]}>
            <LightTooltip title={getTooltipText()} customTooltipSx={customTooltipSx}>
              <span>
                <IconButton
                  onClick={handleMuteClick}
                  disabled={value === MuteStatus.Unsubscribed}
                  size="small"
                  className={classes.icon}
                  style={{ opacity: value === MuteStatus.Unsubscribed ? 0.5 : 1 }}
                >
                  {Icon}
                </IconButton>
              </span>
            </LightTooltip>
          </span>
        }
        open={isPopoverOpen}
        onClose={handleClose}
        sx={muteDaysPopoverStyle}
        tooltipArrowStyle={muteDaysArrowStyle}
        placement={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MuteAlertDaysInput onSend={handleSendMuteAlert} />
      </SgPopover>
      <MuteAlertToast open={showToast} setOpen={setShowToast} action={toastAction} />
    </>
  );
};

export default MuteAlertCell;
