/*eslint prefer-rest-params: "off" */

import { UserAccessType } from "../user/Models";

export const initializeClarity = (projectId, appVersion, userData) => {
  if (window.clarity) {
    setExtendedParameters(userData, appVersion);
  } else {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
      t.onload = () => setExtendedParameters(userData, appVersion);
    })(window, document, "clarity", "script", projectId);
  }
};

const setExtendedParameters = (userData, appVersion) => {
  window.clarity("set", "userId", userData.userName);
  window.clarity("set", "userRole", UserAccessType[userData.userType]);
  window.clarity("set", "appVersion", appVersion);
};
