export const muteAlertToastButtonStyle = {
  borderRadius: "40px 0 0 0",
  border: "1px 0 0 0",
  color: "#1F3171",
  backgroundColor: "#FFFFFF",
  textAlign: "center",
  width: "123px",
  height: "31px",
  letterSpacing: "0.05em",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "17px",
  "&:hover": {
    opacity: 0.7,
    backgroundColor: "#FFFFFF",
  },
};

export const muteAlertToastStyle = {
  "& .MuiPaper-root": {
    width: "400px",
    backgroundColor: "#595959",
    borderRadius: "30px",
    justifyContent: "center",
    letterSpacing: "0.05em",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "23px",
    textAlign: "center",
    whiteSpace: "pre-line",
  },
  "& .MuiSnackbarContent-action": {
    margin: 0,
    borderRadius: "30px 0 0 0",
  },
};
