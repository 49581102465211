import React, { FC } from "react";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { RootState } from "../../features/app/Store";
import { sgDayjsLocale } from "../../features/app/DayjsUtils";
import { pickerStylesError, pickerStyles } from "../datePicker/pickerStyles";

interface ISgDatePickerProps {
  utcValue: Date | null;
  setUtcValue: (value: Date | null) => void;
  label: string;
  className?: string;
  error?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
}

const SgDatePicker: FC<ISgDatePickerProps> = (props) => {
  const siteTimezone = useSelector((state: RootState) => state.site.ianaTimeZoneName);
  const handleChange = (value: Dayjs | null) => {
    props.setUtcValue(value?.toDate() ?? null);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={sgDayjsLocale} localeText={{ fieldMonthPlaceholder: (params) => params.format }}>
      <DatePicker
        className={props.className}
        closeOnSelect={true}
        label={props.label}
        defaultValue={dayjs(props.utcValue)}
        value={dayjs(props.utcValue)}
        timezone={siteTimezone}
        onChange={handleChange}
        disableFuture={props.disableFuture ?? true}
        disablePast={props.disablePast ?? true}
        slotProps={{
          textField: (params) => ({
            ...params,
            sx: props.error ? pickerStylesError : pickerStyles,
            error: props.error,
          }),
        }}
      />
    </LocalizationProvider>
  );
};

export default SgDatePicker;
