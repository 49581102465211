import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAlertsSubscriber } from "../../faults/DTOs";
import { LoadingState } from "../LoadingState";
import {
  deleteAlertsSubscriber,
  getAlertsSubscribers,
  upsertAlertsSubscriber,
} from "../../faults/API";

interface IFaultStoreState {
  loadingState: LoadingState;
  subscribers: IAlertsSubscriber[];
}

export const initState: IFaultStoreState = {
  loadingState: LoadingState.None,
  subscribers: [],
};

export const alertsSubscribersSlice = createSlice({
  name: "alertsSubscribers",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchAlertsSubscribers.pending, (state) => {
        state.loadingState = LoadingState.Pending;
      })
      .addCase(fetchAlertsSubscribers.fulfilled, (state, action) => {
        const { subscribers } = action.payload;
        state.loadingState = LoadingState.Complete;
        state.subscribers = subscribers;
      })
      .addCase(fetchAlertsSubscribers.rejected, (state) => {
        state.loadingState = LoadingState.Error;
      })
      .addCase(removeAlertsSubscriber.pending, (state) => {
        state.loadingState = LoadingState.Pending;
      })
      .addCase(removeAlertsSubscriber.fulfilled, (state) => {
        state.loadingState = LoadingState.Complete;
      })
      .addCase(removeAlertsSubscriber.rejected, (state) => {
        state.loadingState = LoadingState.Error;
      })
      .addCase(updateAlertsSubscriber.pending, (state) => {
        state.loadingState = LoadingState.Pending;
      })
      .addCase(updateAlertsSubscriber.fulfilled, (state) => {
        state.loadingState = LoadingState.Complete;
      })
      .addCase(updateAlertsSubscriber.rejected, (state) => {
        state.loadingState = LoadingState.Error;
      }),
});

export const fetchAlertsSubscribers = createAsyncThunk(
  "faults/fetchAlertsSubscribers",
  async (_, { signal }) => {
    const subscribers = await getAlertsSubscribers(signal);
    return {
      subscribers: subscribers,
    };
  }
);

export const removeAlertsSubscriber = createAsyncThunk(
  "faults/removeAlertsSubscriber",
  async (userId: string, { dispatch, signal }) => {
    await deleteAlertsSubscriber(userId, signal);
    dispatch(fetchAlertsSubscribers());
    return {
      userId,
    };
  }
);

export const updateAlertsSubscriber = createAsyncThunk(
  "faults/upsertAlertsSubscriber",
  async (subscriber: IAlertsSubscriber, { dispatch, signal }) => {
    await upsertAlertsSubscriber(subscriber, signal);
    dispatch(fetchAlertsSubscribers());
  }
);

export const alertsSubscribersReducer = alertsSubscribersSlice.reducer;
