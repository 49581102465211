import axios from "axios";
import APP_CONFIG from "../app/configuration/AppConfig";
import { IExternalFeaturesSettingsDto } from "./ExternalFeaturesSettingsModels";

export async function getExternalFeaturesSettingsAsync(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/ExternalFeatures/settings/${siteId}`;
  const httpResponse = await axios.get<IExternalFeaturesSettingsDto>(url);
  return httpResponse.data;
}

export async function updateExternalFeaturesSettingsAsync(
  siteId: string,
  exterServiceSettings: IExternalFeaturesSettingsDto
) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/ExternalFeatures/settings/${siteId}`;
  await axios.post(url, exterServiceSettings);
}
