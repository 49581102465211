import { IconButton } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { IAlertMetadataMap, AlertUnion } from "../../Models";
import classes from "./AlertNameCell.module.css";

interface IAlertNameCellProps {
  alert: AlertUnion;
  metadata?: IAlertMetadataMap;
  onSetDetailsAlarm: (alert: AlertUnion | undefined) => void;
  isHovered?: boolean;
}

const AlertNameCell = ({ alert, metadata, onSetDetailsAlarm, isHovered }: IAlertNameCellProps) => {
  if (!metadata || alert.kind === undefined) {
    return null;
  }

  return (
    <div className={classes.container}>
      <span>{metadata[alert.alertType].name}</span>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          onSetDetailsAlarm(alert);
        }}
        className={`${classes.iconButton} ${isHovered ? classes.show : ""}`}
      >
        <InfoOutlined className={classes.infoIcon} />
      </IconButton>
    </div>
  );
};

export default AlertNameCell;
