import { FC } from "react";
import SolarGikToast from "../../../SolarGikLib/toast/SolarGikToast";
import { ToastType } from "../../../SolarGikLib/toast/SolarGikToastModels";

interface IScheduleCommandToastProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  isSuccess: boolean;
  message: string;
}

const ScheduleCommandToast: FC<IScheduleCommandToastProps> = ({ open, setOpen, isSuccess, message }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const title = isSuccess ? "Success" : "Error";
  const type = isSuccess ? ToastType.Success : ToastType.Error;

  return (
    <SolarGikToast
      open={open}
      onClose={handleClose}
      title={title}
      message={message}
      type={type}
      buttonText="OK"
    />
  );
};

export default ScheduleCommandToast;
