import { SelectChangeEvent, FormControl, OutlinedInput, MenuItem, Select } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { inputStyle } from "../../../SolarGikLib/fields/InputStyle";
import { TextEnum } from "../../../SolarGikLib/TextStyles";

interface IMultiSelectEditCellProps {
    params: GridRenderEditCellParams;
    options: string[]
}

export const MultiSelectEditCell = ({ params, options }: IMultiSelectEditCellProps) => {
    const selectedOptionBackgroundColor = "#95D8F5";
    const { id, value, field, error } = params;
    const apiRef = useGridApiContext();
    const handleValueChange = (event: SelectChangeEvent) => {
        const newValue = event.target.value;
        if (newValue.includes("All")) {
            apiRef.current.setEditCellValue({ id, field, value: ["All"] });
        } else {
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        }
    };
    return (
        <FormControl sx={{ width: '100%' }} error={error}>
            <Select
                multiple
                size='small'
                value={value}
                onChange={handleValueChange}
                input={<OutlinedInput sx={inputStyle} className={TextEnum.h5} />}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: "30vh"
                        },
                    },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        className={TextEnum.h5}
                        key={option}
                        value={option}
                        sx={{
                            backgroundColor: value.includes(option) ? selectedOptionBackgroundColor : "inherit",
                            "&.Mui-selected": {
                                backgroundColor: selectedOptionBackgroundColor,
                            },
                            "&.Mui-selected:hover": {
                                backgroundColor: selectedOptionBackgroundColor,
                            },
                        }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultiSelectEditCell;
